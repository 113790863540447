import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom'; // Ensure this is imported

import AdminForm from './components/AdminForm';
import ClientView from './components/ClientView';
import ClientLogin from './components/ClientLogin';
import AdminLogin from './components/AdminLogin';
import ClientUsers2 from './components/ClientUsers2';
import ExistingClients from './components/ExistingClients';
import SelectCompany from './components/SelectCompany';
import ClientUsers from './components/ClientUsers';
import AddUsers from './components/AddUsers';
import ClientLogs from './components/ClientLogs';
import SuperAdminDashboard from './components/SuperAdminDashboard';
import AdminClientView from './components/AdminClientView';
import Login from './components/Login';
import ActivityLog from './components/ActivityLog'; // Import the new ActivityLog component
import { checkAuth, checkClientAuth } from './api'; // Import the API functions
import PrivacyPolicy from './components/PrivacyPolicy'; // Import the PrivacyPolicy component
import TermsOfUse from './components/TermsOfUse';
import EmailComponent from './components/EmailComponent';

import Cookies from 'js-cookie'; // Import js-cookie at the top
import ErrorBoundary from './components/ErrorBoundary';

const ProtectedRoute = ({ children, userType }) => {
    const navigate = useNavigate();

    useEffect(() => {
        const checkUserAuth = async () => {
            const storedUser = JSON.parse(localStorage.getItem('user'));
            let token = Cookies.get('token');


            // Migrate token from localStorage/sessionStorage to cookies
            if (storedUser && storedUser.token && !token) {
                token = storedUser.token;
                Cookies.set('token', token, { secure: true, sameSite: 'strict', expires: 60 });
            }

            if (!storedUser && !token) {
                navigate('/admin/login');
                return;
            }

            try {
                const response = await fetch(`https://my-crm-app-be05fb8e206a.herokuapp.com/auth/check?email=${storedUser.email}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                });
                const data = await response.json(); // Read the body of the response
                console.log(data);
                if (data.authorized) {
                    storedUser.userType = data.userType;
                    localStorage.setItem('user', JSON.stringify(storedUser));
                    if (userType && storedUser.userType !== userType) {
                        navigate('/admin/login');
                    }
                } else {
                    Cookies.remove('token');
                    localStorage.removeItem('user');
                    navigate('/admin/login');
                }
            } catch (error) {
                console.error('Error checking auth:', error);
                Cookies.remove('token');
                localStorage.removeItem('user');
                navigate('/admin/login');
            }
        };

        checkUserAuth();
    }, [navigate, userType]);

    return children;
};

const ClientProtectedRoute = ({ children }) => {
    const navigate = useNavigate();
    const { url, clientId } = useParams(); // Get both URL and clientId parameters
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const checkClientAuthStatus = async () => {
            const storedUser = JSON.parse(localStorage.getItem('user'));
            let token = Cookies.get('token');

            // Migrate token from localStorage/sessionStorage to cookies
            if (storedUser && storedUser.token && !token) {
                token = storedUser.token;
                Cookies.set('token', token, { secure: true, sameSite: 'strict', expires: 60 });
            }

            if (!storedUser && !token) {
                navigate('/client/login');
                return;
            }

            try {
                // Check if user is admin
                const adminResponse = await fetch(`https://my-crm-app-be05fb8e206a.herokuapp.com/auth/check?email=${storedUser.email}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                });
                const adminData = await adminResponse.json(); // Read the body of the response
                console.log('Admin check data:', adminData); // Log the response data

                if (adminResponse.status === 200 && adminData.authorized) {
                    if (['Super Admin', 'Admin', 'Account Manager'].includes(adminData.userType)) {
                        setLoading(false);
                        return; // Admin can access any URL
                    } else {
                        navigate('/admin');
                        return;
                    }
                }

                // If not admin, check client authorization
                const clientResponse = await fetch(`https://my-crm-app-be05fb8e206a.herokuapp.com/auth/client/check?email=${storedUser.email}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                });
                const clientData = await clientResponse.json(); // Read the body of the response
                console.log('Client check data:', clientData); // Log the response data

                if (clientResponse.status === 200 && clientData.authorized) {
                    const hasUrlAccess = clientData.urls.includes(url);
                    const hasClientIdAccess = clientData.companyIds.includes(clientId);

                    if (hasUrlAccess || hasClientIdAccess) {
                        setLoading(false);
                    } else {
                        navigate('/client/select-company'); // Redirect if neither URL nor clientId is authorized
                    }
                } else {
                    console.error('Authorization failed:', clientData.error || 'Unknown error');
                    Cookies.remove('token');
                    navigate('/client/login');
                }
            } catch (error) {
                console.error('Error checking client auth:', error);
                Cookies.remove('token');
                navigate('/client/login');
            } finally {
                setLoading(false);
            }
        };

        checkClientAuthStatus();
    }, [navigate, url, clientId]);

    if (loading) {
        return <div>Loading...</div>; // Show a loading indicator while checking auth
    }

    return children;
};

const App = () => {
    return (
        <Router>
            <div className="App">
                <Routes>
                    <Route path="/" element={<Login />} /> {/* New route for the root URL */}
                    <Route path="/admin/login" element={<AdminLogin />} />
                    <Route path="/client/login" element={<ClientLogin />} />
                    <Route path="/client/select-company" element={<SelectCompany />} />

                    <Route
                        path="/client/:url"
                        element={
                            <ClientProtectedRoute>
                                <ClientView />
                            </ClientProtectedRoute>
                        }
                    />
                    <Route
                        path="/client/:clientId/users"
                        element={
                            <ClientProtectedRoute>
                                <ClientUsers />
                            </ClientProtectedRoute>
                        }
                    />
                    <Route
                        path="/admin"
                        element={
                            <ProtectedRoute>
                                <AdminForm />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/existing-portals"
                        element={
                            <ProtectedRoute>
                                <ExistingClients />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/admin/client/:clientId/users"
                        element={
                            <ProtectedRoute>
                                <ClientUsers2 />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/admin/add-users"
                        element={
                            <ProtectedRoute>
                                <AddUsers />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/admin/client/:clientId/logs"
                        element={
                            <ProtectedRoute>
                                <ClientLogs />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/super-admin-dashboard"
                        element={
                            <ProtectedRoute userType="Super Admin">
                                <SuperAdminDashboard />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/admin/client-portal/:url"
                        element={
                            <ProtectedRoute>
                                <ErrorBoundary>
                                    <AdminClientView />
                                </ErrorBoundary>
                            </ProtectedRoute>
                        }
                    />

                    <Route
                        path="/activity-log"
                        element={
                            <ProtectedRoute userType="Super Admin">
                                <ActivityLog />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/team-email"
                        element={
                            <ProtectedRoute>
                                <EmailComponent />
                            </ProtectedRoute>
                        } />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="/terms-of-use" element={<TermsOfUse />} />
                </Routes>
            </div>
        </Router>
    );
};

export default App;
