import React, { useState, useEffect, useCallback } from 'react';
import {
    getClientData, uploadDocument, logClientUserVisit as apiLogClientUserVisit, deleteContactInfo, submitText,
    submitBusinessHours, submitMultipleChoice, submitCheckboxes, addContactInfo, submitMultipleText, deleteSection,
    getDefaultChecklistItems, addChecklistItem, deleteChecklistItem, updateItemName, updateItemDescription, updateSectionName,
    addClientLog, deleteDocument, submitAdSchedule, submitChecklistText, submitConnectIframe, markSectionAsCompleted
} from '../api';

import { useParams } from 'react-router-dom';
import NavBar from './NavBar';
import Footer from './Footer';
import { v4 as uuidv4 } from 'uuid';
import './ClientView.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './ToastifyCustom.css';
import checkIcon from '../assets/icons/check_circle_40dp_FILL1_wght500_GRAD0_opsz40.svg';
import CustomDropdown from './CustomDropdown'; // Import your custom dropdown component
import ReactQuill from 'react-quill-new';
import 'react-quill/dist/quill.snow.css';
import './CustomQuill.css'; // Import the custom CSS file
import confetti from 'canvas-confetti';

const AdminClientView = () => {
    const { url } = useParams();
    const [clientData, setClientData] = useState({});
    const [files, setFiles] = useState({});
    const [text, setText] = useState({});
    const [businessHours, setBusinessHours] = useState({});
    const [multipleChoice, setMultipleChoice] = useState({});
    const [checkboxes, setCheckboxes] = useState({});
    const [contactInfo, setContactInfo] = useState({});
    const [multipleText, setMultipleText] = useState({});
    const [conditionalItems, setConditionalItems] = useState({});
    const [message, setMessage] = useState('');
    const [completedItems, setCompletedItems] = useState(0);
    const [openSection, setOpenSection] = useState(null);
    const [userEmail, setUserEmail] = useState('');
    const [userName, setUserName] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [sectionCompletionStatus, setSectionCompletionStatus] = useState({});
    const [previousSectionStatus, setPreviousSectionStatus] = useState({});
    const [editMode, setEditMode] = useState(false);
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const [itemNames, setItemNames] = useState({});
    const [itemDescriptions, setItemDescriptions] = useState({});
    const [sectionNames, setSectionNames] = useState({});
    const [sectionDueDates, setSectionDueDates] = useState({});
    const [userType, setUserType] = useState('');
    const [progress, setProgress] = useState({});
    const [isEditing, setIsEditing] = useState({}); // Add state for tracking editing
    const [adSchedule, setAdSchedule] = useState({}); // Ensure adSchedule is defined in the state
    const [checklistText, setChecklistText] = useState({});
    const [confettiDisplayed, setConfettiDisplayed] = useState({});




    // New state variables for adding a section
    const [showNewSectionModal, setShowNewSectionModal] = useState(false);
    const [newSectionName, setNewSectionName] = useState('');
    const [newSectionDueDate, setNewSectionDueDate] = useState('');
    const [defaultSections, setDefaultSections] = useState([]);
    const [selectedDefaultSection, setSelectedDefaultSection] = useState('');

    // New state variables for adding a checklist item
    const [showAddItemModal, setShowAddItemModal] = useState(false);
    const [newItemName, setNewItemName] = useState('');
    const [newItemType, setNewItemType] = useState('');
    const [newItemOptions, setNewItemOptions] = useState('');
    const [newItemOptional, setNewItemOptional] = useState(false); // State for the optional checkbox
    const [newItemLocked, setNewItemLocked] = useState(false); // State for the locked checkbox
    const [newItemTextType, setNewItemTextType] = useState('');

    const fetchClientData = useCallback(async () => {
        try {
            const response = await getClientData(url);
            setClientData(response.data);
            const textValues = {};
            const businessHoursValues = {};
            const multipleChoiceValues = {};
            const checkboxValues = {};
            const contactInfoValues = {};
            const multipleTextValues = {};
            const adScheduleValues = {};
            const checklistTextValues = {};
            const conditionalItemsMap = {};

            response.data.checklists.forEach(item => {
                if (item.type === 'text') {
                    textValues[item._id] = item.text_value || '';
                } else if (item.type === 'business_hours') {
                    const businessHours = item.business_hours || {};
                    businessHoursValues[item._id] = {};
                    ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'].forEach(day => {
                        const times = businessHours[day] ? businessHours[day].split(', ') : [];
                        businessHoursValues[item._id][day] = {
                            open: times[0]?.replace('Open: ', '') || '',
                            close: times[1]?.replace('Close: ', '') || ''
                        };
                    });
                } else if (item.type === 'multiple_choice') {
                    multipleChoiceValues[item._id] = item.selected_option || '';
                    item.conditional_items.forEach(condItem => {
                        const conditionalItem = response.data.checklists.find(ci => ci._id === condItem.item_id);
                        if (conditionalItem) {
                            conditionalItemsMap[conditionalItem._id] = {
                                ...conditionalItem,
                                parent_id: item._id,
                                show_on: condItem.show_on,
                                subfolder: conditionalItem.subfolder || "Misc Onboarding Documents"
                            };
                        }
                    });
                } else if (item.type === 'checkboxes') {
                    checkboxValues[item._id] = item.selected_options || [];
                    item.conditional_items.forEach(condItem => {
                        const conditionalItem = response.data.checklists.find(ci => ci._id === condItem.item_id);
                        if (conditionalItem) {
                            conditionalItemsMap[conditionalItem._id] = {
                                ...conditionalItem,
                                parent_id: item._id,
                                show_on: condItem.show_on,
                                subfolder: conditionalItem.subfolder || "Misc Onboarding Documents"
                            };
                        }
                    });

                } else if (item.type === 'contact_info') {
                    contactInfoValues[item._id] = item.contact_info || [];
                } else if (item.type === 'multiple_text') {
                    multipleTextValues[item._id] = item.multiple_text_values.length > 0 ? item.multiple_text_values : [''];
                } else if (item.type === 'ad_schedule') {
                    adScheduleValues[item._id] = item.ad_schedule || [];
                } else if (item.type === 'checklist_text') {
                    checklistTextValues[item._id] = item.checklist_text_values || [];
                }
            });

            setText(textValues);
            setBusinessHours(businessHoursValues);
            setMultipleChoice(multipleChoiceValues);
            setCheckboxes(checkboxValues);
            setContactInfo(contactInfoValues);
            setMultipleText(multipleTextValues);
            setAdSchedule(adScheduleValues);
            setChecklistText(checklistTextValues);
            setConditionalItems(conditionalItemsMap);

            const initialCompletedItems = response.data.checklists.filter(item =>
                !item.optional && (
                    item.type === 'text' ? item.text_value?.trim() :
                        item.type === 'business_hours' ? Object.values(businessHoursValues[item._id]).some(day => day.open?.trim() && day.close?.trim()) :
                            item.type === 'multiple_choice' ? item.selected_option?.trim() :
                                item.type === 'checkboxes' ? item.selected_options.length > 0 :
                                    item.type === 'contact_info' ? item.contact_info.length > 0 :
                                        item.type === 'multiple_text' ? item.multiple_text_values.length > 0 :
                                            item.type === 'ad_schedule' ? item.ad_schedule.length > 0 :
                                                item.type === 'checklist_text' ? item.checklist_text_values.length > 0 :
                                                    isDocumentUploaded(item._id)
                )
            ).length;
            setCompletedItems(initialCompletedItems);

        } catch (error) {
            console.error('Error fetching client data:', error);
            setMessage('Error fetching client data');
        }
    }, [url]);



    const updateConditionalItems = (updatedItems) => {
        setConditionalItems(updatedItems);
    };


    const handleAdScheduleSubmit = async (checklistId) => {
        try {
            const checklistItem = clientData.checklists.find(item => item._id === checklistId);
            if (checklistItem.locked && checklistItem.completed_info?.completed_at) {
                handleLockedItem();
                return;
            }

            await submitAdSchedule({
                checklist_id: checklistId,
                ad_schedule: adSchedule[checklistId],
                user_email: userEmail
            });

            // Save parent item when conditional item is completed
            if (conditionalItems[checklistId]?.parent_id) {
                const parentItemId = conditionalItems[checklistId].parent_id;
                await submitMultipleChoice({
                    checklist_id: parentItemId,
                    selected_option: multipleChoice[parentItemId],
                    user_email: userEmail
                });
            }

            await addClientLog({
                user: userEmail,
                clientId: clientData.client._id,
                action: `Submitted ${checklistItem.item_name}`
            });

            // Update the completion info in the state
            const updatedClientData = { ...clientData };
            const updatedChecklists = updatedClientData.checklists.map(item => {
                if (item._id === checklistId) {
                    const editedInfo = {
                        edited_at: new Date().toISOString(),
                        edited_by: userEmail
                    };

                    if (item.completed_info?.completed_at) {
                        return {
                            ...item,
                            edited_info: item.edited_info ? [...item.edited_info, editedInfo] : [editedInfo]
                        };
                    } else {
                        return {
                            ...item,
                            completed_info: {
                                completed_at: new Date().toISOString(),
                                completed_by: userEmail
                            }
                        };
                    }
                }
                return item;
            });
            updatedClientData.checklists = updatedChecklists;
            setClientData({ ...updatedClientData });

            const updatedConditionalItems = { ...conditionalItems };
            if (updatedConditionalItems[checklistId]) {
                updatedConditionalItems[checklistId] = {
                    ...updatedConditionalItems[checklistId],
                    completed_info: {
                        completed_at: new Date().toISOString(),
                        completed_by: userEmail
                    },
                    edited_info: updatedConditionalItems[checklistId].edited_info ? [...updatedConditionalItems[checklistId].edited_info, {
                        edited_at: new Date().toISOString(),
                        edited_by: userEmail
                    }] : [{
                        edited_at: new Date().toISOString(),
                        edited_by: userEmail
                    }]
                };
            }
            updateConditionalItems(updatedConditionalItems);

            setMessage('Ad schedule submitted successfully');
            setUnsavedChanges(false);
        } catch (error) {
            setMessage('Error submitting ad schedule');
        }
    };




    const fetchDefaultChecklistItems = useCallback(async () => {
        try {
            const response = await getDefaultChecklistItems();
            setDefaultSections(response);
        } catch (error) {
            console.error('Error fetching default checklist items:', error);
        }
    }, []);

    useEffect(() => {
        fetchClientData();
        fetchDefaultChecklistItems();
    }, [fetchClientData, fetchDefaultChecklistItems]);

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user') || sessionStorage.getItem('user'));
        if (user && user.email) {
            setUserEmail(user.email);
            setUserType(user.userType); // Fetch user type
            if (user.firstName && user.lastName) {
                setUserName(`${user.firstName} ${user.lastName}`);
            } else {
                setUserName(user.name);
            }
        }

        if (!sessionStorage.getItem('loggedVisit') && clientData.client?._id) {
            logClientUserVisit();
            sessionStorage.setItem('loggedVisit', 'true');
        }
    }, [clientData.client]);

    useEffect(() => {
        if (!clientData.checklists) return;

        const groupedChecklists = clientData.checklists.reduce((acc, item) => {
            if (!item.parent_item_id) {
                const [section, itemName] = item.item_name.split(' - ');
                if (!acc[section]) acc[section] = [];
                acc[section].push({ ...item, itemName });
            }
            return acc;
        }, {});

        const newSectionStatus = {};

        Object.keys(groupedChecklists).forEach(section => {
            const completed = isSectionCompleted(section, groupedChecklists);
            newSectionStatus[section] = completed;
        });

        if (JSON.stringify(newSectionStatus) !== JSON.stringify(sectionCompletionStatus)) {
            setSectionCompletionStatus(newSectionStatus);
            setPreviousSectionStatus(newSectionStatus);  // Update previous status with the new status
        }
    }, [clientData.checklists, sectionCompletionStatus, previousSectionStatus, clientData.client]);

    useEffect(() => {
        const handleBeforeUnload = async (event) => {
            if (unsavedChanges) {
                await saveUnsavedChanges();
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [unsavedChanges, isEditing, text, businessHours, multipleChoice, checkboxes, contactInfo, multipleText]);

    const logClientUserVisit = async () => {
        const user = JSON.parse(localStorage.getItem('user') || sessionStorage.getItem('user'));
        if (user && user.token) {
            try {
                await apiLogClientUserVisit({
                    userId: user.email,
                    clientId: clientData.client._id,
                }, {
                    headers: {
                        Authorization: `Bearer ${user.token}`
                    }
                });
            } catch (error) {
                console.error('Error logging login:', error);
            }
        } else {
            console.error('User data not found in storage');
        }
    };

    const isDocumentUploaded = useCallback((checklistId) => {
        return clientData.documents?.some(doc => doc.checklist_id === checklistId);
    }, [clientData.documents]);

    const handleFileChange = (checklistId, fileId) => (e) => {
        const selectedFiles = Array.from(e.target.files);
        setFiles(prevFiles => {
            const updatedFiles = { ...prevFiles };
            if (!updatedFiles[checklistId]) {
                updatedFiles[checklistId] = {};
            }
            updatedFiles[checklistId][fileId] = selectedFiles; // Store the array of files
            setUnsavedChanges(true);
            return updatedFiles;
        });
    };



    const getEndTimes = () => {
        const times = [];
        let currentHour = 12;
        let currentMinute = 0;
        let currentPeriod = 'AM';

        while (!(currentHour === 11 && currentMinute === 30 && currentPeriod === 'PM')) {
            times.push(`${currentHour}:${currentMinute === 0 ? '00' : currentMinute} ${currentPeriod}`);

            currentMinute += 30;
            if (currentMinute === 60) {
                currentMinute = 0;
                currentHour = (currentHour % 12) + 1;
                if (currentHour === 12) {
                    currentPeriod = currentPeriod === 'AM' ? 'PM' : 'AM';
                }
            }
        }

        // Add final time of 11:30 PM
        times.push(`11:30 PM`);

        return times;
    };





    const handleAdScheduleChange = (checklistId, index, field) => (e) => {
        setAdSchedule(prevAdSchedule => {
            const updatedAdSchedule = {
                ...prevAdSchedule,
                [checklistId]: prevAdSchedule[checklistId] ? [...prevAdSchedule[checklistId]] : []
            };
            if (!updatedAdSchedule[checklistId][index]) {
                updatedAdSchedule[checklistId][index] = { day: '', runAds: false, startTime: '', endTime: '' };
            }
            if (field === 'runAds') {
                updatedAdSchedule[checklistId][index][field] = e.target.checked;
            } else {
                updatedAdSchedule[checklistId][index][field] = e.target.value;
            }
            updatedAdSchedule[checklistId][index].day = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'][index];
            setIsEditing(prevIsEditing => ({ ...prevIsEditing, [checklistId]: true }));
            setUnsavedChanges(true);
            return updatedAdSchedule;
        });
    };

    const handleItemDescriptionChange = (itemId) => (e) => {
        const newValue = e.target.value;
        setItemDescriptions(prevDescriptions => ({
            ...prevDescriptions,
            [itemId]: newValue
        }));
        setUnsavedChanges(true);
    };

    const handleSaveDescriptions = async () => {
        await Promise.all(
            Object.keys(itemDescriptions).map(async itemId => {
                const newDescription = itemDescriptions[itemId];
                const existingItem = clientData.checklists.find(item => item._id === itemId);
                if (existingItem && existingItem.description !== newDescription) {
                    try {
                        await updateItemDescription({ checklist_id: itemId, description: newDescription, user_email: userEmail });
                    } catch (error) {
                        console.error(`Failed to update description for item ID ${itemId}:`, error);
                    }
                }
            })
        );
        await fetchClientData();
    };

    const handleSectionNameChange = (oldSectionName) => (e) => {
        const newSectionName = e.target.value;
        setSectionNames((prevSectionNames) => ({
            ...prevSectionNames,
            [oldSectionName]: newSectionName,
        }));
        if (sectionDueDates[oldSectionName]) {
            setSectionDueDates((prevDueDates) => ({
                ...prevDueDates,
                [newSectionName]: prevDueDates[oldSectionName],
            }));
        }
        setUnsavedChanges(true);
    };


    const handleDueDateChange = (sectionName) => (e) => {
        const newDueDate = e.target.value;
        setSectionDueDates((prevDueDates) => ({
            ...prevDueDates,
            [sectionName]: newDueDate,
        }));
        setUnsavedChanges(true);
    };



    const handleSaveSectionNames = async () => {
        try {
            await Promise.all(
                Object.keys(sectionDueDates).map(async (section) => {
                    const newSectionName = sectionNames[section] || section;
                    const dueDate = sectionDueDates[section] || sectionDueDates[newSectionName] || null;
                    const payload = { oldSectionName: section, newSectionName, dueDate, client_id: clientData.client._id };
                    if (dueDate) {
                        const oldDueDate = clientData.client.due_dates.find(d => d.section === section)?.due_date;
                        if (oldDueDate && oldDueDate !== dueDate) {
                            await logClientAction(`Changed the due date of ${section} section from ${formatDate(oldDueDate)} to ${formatDate(dueDate)}`);
                        }
                    }
                    if (newSectionName && newSectionName !== section) {
                        await logClientAction(`Changed "${section}" section to "${newSectionName}"`);
                    }
                    await updateSectionName(payload);
                })
            );
            toast.success('Section names and due dates updated successfully');
            fetchClientData(); // Ensure client data is re-fetched after updates
            setUnsavedChanges(false);
        } catch (error) {
            toast.error('Error updating section names and due dates');
            console.error('Error updating section names and due dates:', error);
        }
    };


    const updateSectionWithRetry = async (payload, retryCount = 3) => {
        try {
            await updateSectionName(payload);
        } catch (error) {
            if (retryCount > 0 && error.name === 'VersionError') {
                console.warn(`Version conflict detected. Retrying... (${3 - retryCount + 1}/3)`);
                await fetchClientData(); // Re-fetch the latest client data
                await updateSectionWithRetry(payload, retryCount - 1);
            } else {
                throw error;
            }
        }
    };




    const logClientAction = async (message) => {
        try {
            await addClientLog({
                user: userEmail,
                clientId: clientData.client._id,
                action: message,
            });
        } catch (error) {
            console.error('Error logging action:', error);
        }
    };





    const handleAddFileInput = (checklistId) => {
        const newFileId = uuidv4();
        setFiles(prevFiles => {
            setUnsavedChanges(true);
            return {
                ...prevFiles,
                [checklistId]: {
                    ...(prevFiles[checklistId] || {}),
                    [newFileId]: null
                }
            };
        });
    };

    const handleDeleteFileInput = (checklistId, fileId, index) => {
        setFiles(prevFiles => {
            const updatedFiles = { ...prevFiles };
            if (updatedFiles[checklistId] && updatedFiles[checklistId][fileId]) {
                updatedFiles[checklistId][fileId].splice(index, 1);
                if (updatedFiles[checklistId][fileId].length === 0) {
                    delete updatedFiles[checklistId][fileId];
                }
            }
            setUnsavedChanges(true);
            return updatedFiles;
        });
    };


    const handleTextChange = (checklistId) => (e) => {
        setText(prevText => ({ ...prevText, [checklistId]: e.target.value }));
        setUnsavedChanges(true);
        setIsEditing(prevEditing => ({ ...prevEditing, [checklistId]: e.target.value.trim() !== '' }));

        const toolbar = document.getElementById(`toolbar-${checklistId}`);
        if (toolbar) {
            toolbar.classList.add('show');
        }
    };

    const handleBusinessHoursChange = (checklistId, day, timeType) => (e) => {
        setBusinessHours(prevBusinessHours => {
            const updatedBusinessHours = {
                ...prevBusinessHours,
                [checklistId]: {
                    ...prevBusinessHours[checklistId],
                    [day]: {
                        ...prevBusinessHours[checklistId]?.[day],
                        [timeType]: e.target.value
                    }
                }
            };

            // Check if all 7 days have valid values
            const allDaysFilled = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
                .every(day => {
                    const open = updatedBusinessHours[checklistId]?.[day]?.open;
                    const close = updatedBusinessHours[checklistId]?.[day]?.close;
                    return open?.trim() !== '' && (close?.trim() !== '' || open === 'Closed' || open === '24HR');
                });

            setIsEditing(prevIsEditing => ({
                ...prevIsEditing,
                [checklistId]: allDaysFilled // Only allow editing if all days are filled appropriately
            }));
            setUnsavedChanges(true);

            return updatedBusinessHours;
        });
    };






    const handleItemNameChange = (itemId) => (e) => {
        setItemNames(prevItemNames => ({
            ...prevItemNames,
            [itemId]: e.target.value
        }));
        setUnsavedChanges(true);
    };

    const handleMultipleChoiceChange = (checklistId) => (e) => {
        const selectedOption = e.target.value;
        setMultipleChoice(prevMultipleChoice => ({
            ...prevMultipleChoice,
            [checklistId]: selectedOption
        }));
        setIsEditing(prevIsEditing => ({ ...prevIsEditing, [checklistId]: true }));
        setUnsavedChanges(true);
    };

    useEffect(() => {
        setConditionalItems(prevConditionalItems => ({
            ...prevConditionalItems,
        }));
    }, [multipleChoice]);

    const handleCheckboxChange = (checklistId, option) => (e) => {
        setCheckboxes(prevCheckboxes => {
            const updatedCheckboxes = { ...prevCheckboxes };
            if (!updatedCheckboxes[checklistId]) {
                updatedCheckboxes[checklistId] = [];
            }
            if (e.target.checked) {
                updatedCheckboxes[checklistId].push(option);
            } else {
                updatedCheckboxes[checklistId] = updatedCheckboxes[checklistId].filter(item => item !== option);
            }
            setIsEditing(prevIsEditing => ({ ...prevIsEditing, [checklistId]: true }));
            setUnsavedChanges(true);
            return updatedCheckboxes;
        });
    };

    const handleContactInfoChange = (checklistId, index, field) => (e) => {
        setContactInfo(prevContactInfo => {
            setUnsavedChanges(true);
            const updatedContactInfo = { ...prevContactInfo };
            if (!updatedContactInfo[checklistId]) {
                updatedContactInfo[checklistId] = [];
            }
            if (!updatedContactInfo[checklistId][index]) {
                updatedContactInfo[checklistId][index] = { contactName: '', contactEmail: '', contactPhone: '' };
            }
            updatedContactInfo[checklistId][index][field] = e.target.value;
            return updatedContactInfo;
        });
    };

    const handleAddContactInfo = (checklistId) => {
        setContactInfo(prevContactInfo => ({
            ...prevContactInfo,
            [checklistId]: [
                ...(prevContactInfo[checklistId] || []),
                { contactName: '', contactEmail: '', contactPhone: '' }
            ]
        }));
        setIsEditing(prevIsEditing => ({ ...prevIsEditing, [checklistId]: true }));
        setUnsavedChanges(true);
    };

    const handleDeleteContactInfo = async (checklistId, index) => {
        try {
            const contact = contactInfo[checklistId][index];
            await deleteContactInfo({
                checklist_id: checklistId,
                contact_name: contact.contactName,
                contact_email: contact.contactEmail,
                contact_phone: contact.contactPhone,
                user_email: userEmail
            });
            setContactInfo(prevContactInfo => {
                const updatedContactInfo = { ...prevContactInfo };
                if (updatedContactInfo[checklistId]) {
                    updatedContactInfo[checklistId].splice(index, 1);
                }
                return updatedContactInfo;
            });
            toast.success('Contact info deleted successfully');
            setUnsavedChanges(true);
        } catch (error) {
            toast.error('Error deleting contact info');
        }
    };

    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const isValidPhone = (phone) => {
        // Updated phone regex to match standard US phone numbers
        const phoneRegex = /^\(?([0-9]{3})\)?[-.●]?([0-9]{3})[-.●]?([0-9]{4})$/;
        return phoneRegex.test(phone);
    };

    const saveUnsavedChanges = async () => {
        try {
            const savePromises = [];

            Object.keys(isEditing).forEach(checklistId => {
                if (isEditing[checklistId]) {
                    const checklistItem = clientData.checklists.find(item => item._id === checklistId);

                    // Skip invalid inputs
                    if (checklistItem.type === 'text') {
                        const textValue = text[checklistId];
                        if ((checklistItem.text_input_type === 'email' && !isValidEmail(textValue)) ||
                            (checklistItem.text_input_type === 'phone' && !isValidPhone(textValue))) {
                            return;
                        }
                    } else if (checklistItem.type === 'multiple_text') {
                        for (const textValue of multipleText[checklistId]) {
                            if ((checklistItem.text_input_type === 'email' && !isValidEmail(textValue)) ||
                                (checklistItem.text_input_type === 'phone' && !isValidPhone(textValue))) {
                                return;
                            }
                        }
                    }

                    if (checklistItem.type === 'text') {
                        savePromises.push(submitText({
                            checklist_id: checklistId,
                            text_value: text[checklistId],
                            user_email: userEmail
                        }));
                    } else if (checklistItem.type === 'business_hours') {
                        const formattedBusinessHours = {};
                        ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'].forEach(day => {
                            const open = businessHours[checklistId]?.[day]?.open || '';
                            const close = businessHours[checklistId]?.[day]?.close || '';
                            formattedBusinessHours[day] = `Open: ${open}, Close: ${close}`;
                        });
                        savePromises.push(submitBusinessHours({
                            checklist_id: checklistId,
                            business_hours: formattedBusinessHours,
                            user_email: userEmail
                        }));
                    } else if (checklistItem.type === 'multiple_choice') {
                        savePromises.push(submitMultipleChoice({
                            checklist_id: checklistId,
                            selected_option: multipleChoice[checklistId],
                            user_email: userEmail
                        }));
                    } else if (checklistItem.type === 'checkboxes') {
                        savePromises.push(submitCheckboxes({
                            checklist_id: checklistId,
                            selected_options: checkboxes[checklistId] || [],
                            user_email: userEmail
                        }));
                    } else if (checklistItem.type === 'contact_info') {
                        contactInfo[checklistId].forEach((contact, index) => {
                            savePromises.push(addContactInfo({
                                checklist_id: checklistId,
                                contact_name: contact.contactName,
                                contact_email: contact.contactEmail,
                                contact_phone: contact.contactPhone,
                                user_email: userEmail
                            }));
                        });
                    } else if (checklistItem.type === 'multiple_text') {
                        savePromises.push(submitMultipleText({
                            checklist_id: checklistId,
                            text_values: multipleText[checklistId],
                            user_email: userEmail
                        }));
                    } else if (checklistItem.type === 'ad_schedule') {
                        savePromises.push(submitAdSchedule({
                            checklist_id: checklistId,
                            ad_schedule: adSchedule[checklistId],
                            user_email: userEmail
                        }));
                    } else if (checklistItem.type === 'checklist_text') {
                        savePromises.push(submitChecklistText({
                            checklist_id: checklistId,
                            checklist_text_values: checklistText[checklistId],
                            user_email: userEmail
                        }));
                    }
                }
            });

            await Promise.all(savePromises);
            setUnsavedChanges(false);
        } catch (error) {
            console.error('Error saving unsaved changes:', error);
        }
    };



    const handleMultipleTextChange = (checklistId, index) => (e) => {
        setMultipleText(prevMultipleText => {
            const updatedMultipleText = { ...prevMultipleText };
            if (!updatedMultipleText[checklistId]) {
                updatedMultipleText[checklistId] = [''];
            }
            updatedMultipleText[checklistId][index] = e.target.value;
            setIsEditing(prevIsEditing => ({ ...prevIsEditing, [checklistId]: true }));
            setUnsavedChanges(true);
            return updatedMultipleText;
        });
        adjustTextareaHeight(e.target);
    };

    const handleAddMultipleText = (checklistId) => {
        setMultipleText(prevMultipleText => ({
            ...prevMultipleText,
            [checklistId]: [
                ...(prevMultipleText[checklistId] || []),
                ''
            ]
        }));
        setIsEditing(prevIsEditing => ({ ...prevIsEditing, [checklistId]: true }));
        setUnsavedChanges(true);
    };

    const handleDeleteMultipleText = (checklistId, index) => {
        setMultipleText(prevMultipleText => {
            const updatedMultipleText = { ...prevMultipleText };
            if (updatedMultipleText[checklistId]) {
                updatedMultipleText[checklistId].splice(index, 1);
            }
            setIsEditing(prevIsEditing => ({ ...prevIsEditing, [checklistId]: true }));
            setUnsavedChanges(true);
            return updatedMultipleText;
        });
    };

    const handleUpload = async (checklistId, fileId, index) => {
        // Ensure files state exists and has the required structure
        if (!files || !files[checklistId] || !files[checklistId][fileId]) {
            console.error('File not found');
            return;
        }

        // Ensure file at given index exists
        const file = files[checklistId][fileId][index];
        if (!file) {
            console.error('File at the given index not found');
            return;
        }

        const formData = new FormData();
        formData.append('file', file);
        formData.append('client_id', clientData.client._id);
        formData.append('checklist_id', checklistId);
        formData.append('user_email', userEmail);

        // Determine subfolder name
        let subFolderName = "Misc Onboarding Documents"; // Default value
        const checklistItem = clientData.checklists.find(item => item._id === checklistId);

        if (checklistItem && checklistItem.subfolder) {
            subFolderName = checklistItem.subfolder;
        }

        formData.append('sub_folder_name', subFolderName);

        try {
            await uploadDocument(formData, (event) => {
                const percentCompleted = Math.round((event.loaded * 100) / event.total);
                setProgress(prevProgress => {
                    const newProgress = { ...prevProgress };

                    // Ensure nested objects exist before setting new properties
                    if (!newProgress[checklistId]) newProgress[checklistId] = {};
                    if (!newProgress[checklistId][fileId]) newProgress[checklistId][fileId] = {};

                    newProgress[checklistId][fileId][index] = percentCompleted;
                    return newProgress;
                });
            });

            setMessage('Document uploaded successfully');

            // Clear the file entry after successful upload
            setFiles(prevFiles => {
                const updatedFiles = { ...prevFiles };
                if (updatedFiles[checklistId] && updatedFiles[checklistId][fileId]) {
                    updatedFiles[checklistId][fileId].splice(index, 1);
                    if (updatedFiles[checklistId][fileId].length === 0) {
                        delete updatedFiles[checklistId][fileId];
                    }
                }
                return updatedFiles;
            });

            setProgress(prevProgress => {
                const updatedProgress = { ...prevProgress };
                if (updatedProgress[checklistId] && updatedProgress[checklistId][fileId]) {
                    delete updatedProgress[checklistId][fileId][index];
                    if (Object.keys(updatedProgress[checklistId][fileId]).length === 0) {
                        delete updatedProgress[checklistId][fileId];
                    }
                }
                return updatedProgress;
            });

            // Save parent item when conditional item is completed
            if (conditionalItems[checklistId]?.parent_id) {
                const parentItemId = conditionalItems[checklistId].parent_id;
                await submitMultipleChoice({
                    checklist_id: parentItemId,
                    selected_option: multipleChoice[parentItemId],
                    user_email: userEmail
                });
            }

            fetchClientData();
            setUnsavedChanges(false);
        } catch (error) {
            setMessage('Error uploading document');
        }
    };


    const handleLockedItem = () => {
        setModalMessage('This is a significant item and requires notifying Ferocious to make changes. Please contact Ferocious Media with any new changes to this item.');
        setShowModal(true);
    };

    const handleTextSubmit = async (checklistId) => {
        try {
            const checklistItem = clientData.checklists.find(item => item._id === checklistId);
            if (checklistItem.locked && checklistItem.completed_info?.completed_at) {
                handleLockedItem();
                return;
            }

            // Validation logic
            const textValue = text[checklistId];
            if (checklistItem.text_input_type === 'email' && !isValidEmail(textValue)) {
                toast.error('Invalid email format');
                return;
            }
            if (checklistItem.text_input_type === 'phone' && !isValidPhone(textValue)) {
                toast.error('Invalid phone number format');
                return;
            }

            await submitText({
                checklist_id: checklistId,
                text_value: textValue,
                user_email: userEmail
            });

            // Save parent item when conditional item is completed
            if (conditionalItems[checklistId]?.parent_id) {
                const parentItemId = conditionalItems[checklistId].parent_id;
                await submitMultipleChoice({
                    checklist_id: parentItemId,
                    selected_option: multipleChoice[parentItemId],
                    user_email: userEmail
                });
            }

            await addClientLog({
                user: userEmail,
                clientId: clientData.client._id,
                action: `Submitted ${checklistItem.item_name}`
            });

            // Update the completion info in the state
            const updatedClientData = { ...clientData };
            const updatedChecklists = updatedClientData.checklists.map(item => {
                if (item._id === checklistId) {
                    const editedInfo = {
                        edited_at: new Date().toISOString(),
                        edited_by: userEmail
                    };

                    if (item.completed_info?.completed_at) {
                        return {
                            ...item,
                            edited_info: item.edited_info ? [...item.edited_info, editedInfo] : [editedInfo]
                        };
                    } else {
                        return {
                            ...item,
                            completed_info: {
                                completed_at: new Date().toISOString(),
                                completed_by: userEmail
                            }
                        };
                    }
                }
                return item;
            });
            updatedClientData.checklists = updatedChecklists;
            setClientData({ ...updatedClientData });

            const updatedConditionalItems = { ...conditionalItems };
            if (updatedConditionalItems[checklistId]) {
                updatedConditionalItems[checklistId] = {
                    ...updatedConditionalItems[checklistId],
                    completed_info: {
                        completed_at: new Date().toISOString(),
                        completed_by: userEmail
                    },
                    edited_info: updatedConditionalItems[checklistId].edited_info ? [...updatedConditionalItems[checklistId].edited_info, {
                        edited_at: new Date().toISOString(),
                        edited_by: userEmail
                    }] : [{
                        edited_at: new Date().toISOString(),
                        edited_by: userEmail
                    }]
                };
            }
            updateConditionalItems(updatedConditionalItems);

            setMessage('Text submitted successfully');
            setUnsavedChanges(false);
        } catch (error) {
            setMessage('Error submitting text');
        }
    };




    const handleBusinessHoursSubmit = async (checklistId) => {
        try {
            const checklistItem = clientData.checklists.find(item => item._id === checklistId);
            if (checklistItem.locked && checklistItem.completed_info?.completed_at) {
                handleLockedItem();
                return;
            }

            const formattedBusinessHours = {};
            ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'].forEach(day => {
                const open = businessHours[checklistId]?.[day]?.open || '';
                const close = businessHours[checklistId]?.[day]?.close || '';
                formattedBusinessHours[day] = `Open: ${open}, Close: ${close}`;
            });

            await submitBusinessHours({
                checklist_id: checklistId,
                business_hours: formattedBusinessHours,
                user_email: userEmail
            });

            // Save parent item when conditional item is completed
            if (conditionalItems[checklistId]?.parent_id) {
                const parentItemId = conditionalItems[checklistId].parent_id;
                await submitMultipleChoice({
                    checklist_id: parentItemId,
                    selected_option: multipleChoice[parentItemId],
                    user_email: userEmail
                });
            }

            await addClientLog({
                user: userEmail,
                clientId: clientData.client._id,
                action: `Submitted ${checklistItem.item_name}`
            });

            // Update the completion info in the state
            const updatedClientData = { ...clientData };
            const updatedChecklists = updatedClientData.checklists.map(item => {
                if (item._id === checklistId) {
                    const editedInfo = {
                        edited_at: new Date().toISOString(),
                        edited_by: userEmail
                    };

                    if (item.completed_info?.completed_at) {
                        return {
                            ...item,
                            edited_info: item.edited_info ? [...item.edited_info, editedInfo] : [editedInfo]
                        };
                    } else {
                        return {
                            ...item,
                            completed_info: {
                                completed_at: new Date().toISOString(),
                                completed_by: userEmail
                            }
                        };
                    }
                }
                return item;
            });
            updatedClientData.checklists = updatedChecklists;
            setClientData({ ...updatedClientData });

            const updatedConditionalItems = { ...conditionalItems };
            if (updatedConditionalItems[checklistId]) {
                updatedConditionalItems[checklistId] = {
                    ...updatedConditionalItems[checklistId],
                    completed_info: {
                        completed_at: new Date().toISOString(),
                        completed_by: userEmail
                    },
                    edited_info: updatedConditionalItems[checklistId].edited_info ? [...updatedConditionalItems[checklistId].edited_info, {
                        edited_at: new Date().toISOString(),
                        edited_by: userEmail
                    }] : [{
                        edited_at: new Date().toISOString(),
                        edited_by: userEmail
                    }]
                };
            }
            updateConditionalItems(updatedConditionalItems);

            setMessage('Business hours submitted successfully');
            setUnsavedChanges(false);
        } catch (error) {
            setMessage('Error submitting business hours');
        }
    };




    const formatDateForInput = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };


    const handleMultipleChoiceSubmit = async (checklistId) => {
        try {
            const checklistItem = clientData.checklists.find(item => item._id === checklistId);
            if (checklistItem.locked && checklistItem.completed_info?.completed_at) {
                handleLockedItem();
                return;
            }
            await submitMultipleChoice({
                checklist_id: checklistId,
                selected_option: multipleChoice[checklistId],
                user_email: userEmail
            });

            // Save parent item when conditional item is completed
            if (conditionalItems[checklistId]?.parent_id) {
                const parentItemId = conditionalItems[checklistId].parent_id;
                await submitMultipleChoice({
                    checklist_id: parentItemId,
                    selected_option: multipleChoice[parentItemId],
                    user_email: userEmail
                });
            }

            await addClientLog({
                user: userEmail,
                clientId: clientData.client._id,
                action: `Submitted ${checklistItem.item_name}`
            });

            // Update the completion info in the state
            const updatedClientData = { ...clientData };
            const updatedChecklists = updatedClientData.checklists.map(item => {
                if (item._id === checklistId) {
                    const editedInfo = {
                        edited_at: new Date().toISOString(),
                        edited_by: userEmail
                    };

                    if (item.completed_info?.completed_at) {
                        return {
                            ...item,
                            edited_info: item.edited_info ? [...item.edited_info, editedInfo] : [editedInfo]
                        };
                    } else {
                        return {
                            ...item,
                            completed_info: {
                                completed_at: new Date().toISOString(),
                                completed_by: userEmail
                            }
                        };
                    }
                }
                return item;
            });
            updatedClientData.checklists = updatedChecklists;
            setClientData({ ...updatedClientData });

            const updatedConditionalItems = { ...conditionalItems };
            if (updatedConditionalItems[checklistId]) {
                updatedConditionalItems[checklistId] = {
                    ...updatedConditionalItems[checklistId],
                    completed_info: {
                        completed_at: new Date().toISOString(),
                        completed_by: userEmail
                    },
                    edited_info: updatedConditionalItems[checklistId].edited_info ? [...updatedConditionalItems[checklistId].edited_info, {
                        edited_at: new Date().toISOString(),
                        edited_by: userEmail
                    }] : [{
                        edited_at: new Date().toISOString(),
                        edited_by: userEmail
                    }]
                };
            }
            updateConditionalItems(updatedConditionalItems);

            setMessage('Multiple choice submitted successfully');
            setUnsavedChanges(false);
        } catch (error) {
            setMessage('Error submitting multiple choice');
        }
    };




    const handleCheckboxSubmit = async (checklistId) => {
        try {
            const checklistItem = clientData.checklists.find(item => item._id === checklistId);
            if (checklistItem.locked && checklistItem.completed_info?.completed_at) {
                handleLockedItem();
                return;
            }
            await submitCheckboxes({
                checklist_id: checklistId,
                selected_options: checkboxes[checklistId] || [],
                user_email: userEmail
            });
            await addClientLog({
                user: userEmail,
                clientId: clientData.client._id,
                action: `Submitted ${checklistItem.item_name}`
            });

            // Update the completion info in the state
            const updatedClientData = { ...clientData };
            const updatedChecklists = updatedClientData.checklists.map(item => {
                if (item._id === checklistId) {
                    const editedInfo = {
                        edited_at: new Date().toISOString(),
                        edited_by: userEmail
                    };

                    if (item.completed_info?.completed_at) {
                        return {
                            ...item,
                            edited_info: item.edited_info ? [...item.edited_info, editedInfo] : [editedInfo]
                        };
                    } else {
                        return {
                            ...item,
                            completed_info: {
                                completed_at: new Date().toISOString(),
                                completed_by: userEmail
                            }
                        };
                    }
                }
                return item;
            });
            updatedClientData.checklists = updatedChecklists;
            setClientData({ ...updatedClientData });

            const updatedConditionalItems = { ...conditionalItems };
            if (updatedConditionalItems[checklistId]) {
                updatedConditionalItems[checklistId] = {
                    ...updatedConditionalItems[checklistId],
                    completed_info: {
                        completed_at: new Date().toISOString(),
                        completed_by: userEmail
                    },
                    edited_info: updatedConditionalItems[checklistId].edited_info ? [...updatedConditionalItems[checklistId].edited_info, {
                        edited_at: new Date().toISOString(),
                        edited_by: userEmail
                    }] : [{
                        edited_at: new Date().toISOString(),
                        edited_by: userEmail
                    }]
                };
            }
            updateConditionalItems(updatedConditionalItems);

            setMessage('Checkboxes submitted successfully');
            setUnsavedChanges(false);
        } catch (error) {
            setMessage('Error submitting checkboxes');
        }
    };



    const handleContactInfoSubmit = async (checklistId, index) => {
        try {
            const checklistItem = clientData.checklists.find(item => item._id === checklistId);
            if (checklistItem.locked && checklistItem.completed_info?.completed_at) {
                handleLockedItem();
                return;
            }

            const contact = contactInfo[checklistId][index];
            await addContactInfo({
                checklist_id: checklistId,
                contact_name: contact.contactName,
                contact_email: contact.contactEmail,
                contact_phone: contact.contactPhone,
                user_email: userEmail
            });

            // Save parent item when conditional item is completed
            if (conditionalItems[checklistId]?.parent_id) {
                const parentItemId = conditionalItems[checklistId].parent_id;
                await submitMultipleChoice({
                    checklist_id: parentItemId,
                    selected_option: multipleChoice[parentItemId],
                    user_email: userEmail
                });
            }

            await addClientLog({
                user: userEmail,
                clientId: clientData.client._id,
                action: `Submitted ${checklistItem.item_name}`
            });

            // Update the completion info in the state
            const updatedClientData = { ...clientData };
            const updatedChecklists = updatedClientData.checklists.map(item => {
                if (item._id === checklistId) {
                    const editedInfo = {
                        edited_at: new Date().toISOString(),
                        edited_by: userEmail
                    };

                    if (item.completed_info?.completed_at) {
                        return {
                            ...item,
                            edited_info: item.edited_info ? [...item.edited_info, editedInfo] : [editedInfo]
                        };
                    } else {
                        return {
                            ...item,
                            completed_info: {
                                completed_at: new Date().toISOString(),
                                completed_by: userEmail
                            }
                        };
                    }
                }
                return item;
            });
            updatedClientData.checklists = updatedChecklists;
            setClientData({ ...updatedClientData });

            const updatedConditionalItems = { ...conditionalItems };
            if (updatedConditionalItems[checklistId]) {
                updatedConditionalItems[checklistId] = {
                    ...updatedConditionalItems[checklistId],
                    completed_info: {
                        completed_at: new Date().toISOString(),
                        completed_by: userEmail
                    },
                    edited_info: updatedConditionalItems[checklistId].edited_info ? [...updatedConditionalItems[checklistId].edited_info, {
                        edited_at: new Date().toISOString(),
                        edited_by: userEmail
                    }] : [{
                        edited_at: new Date().toISOString(),
                        edited_by: userEmail
                    }]
                };
            }
            updateConditionalItems(updatedConditionalItems);

            setMessage('Contact info submitted successfully');
            setUnsavedChanges(false);
        } catch (error) {
            setMessage('Error submitting contact info');
        }
    };




    const handleMultipleTextSubmit = async (checklistId) => {
        try {
            const checklistItem = clientData.checklists.find(item => item._id === checklistId);
            if (checklistItem.locked && checklistItem.completed_info?.completed_at) {
                handleLockedItem();
                return;
            }

            // Validation logic
            for (const textValue of multipleText[checklistId]) {
                if (checklistItem.text_input_type === 'email' && !isValidEmail(textValue)) {
                    toast.error('Invalid email format');
                    return;
                }
                if (checklistItem.text_input_type === 'phone' && !isValidPhone(textValue)) {
                    toast.error('Invalid phone number format');
                    return;
                }
            }

            await submitMultipleText({
                checklist_id: checklistId,
                text_values: multipleText[checklistId],
                user_email: userEmail
            });

            // Save parent item when conditional item is completed
            if (conditionalItems[checklistId]?.parent_id) {
                const parentItemId = conditionalItems[checklistId].parent_id;
                await submitMultipleChoice({
                    checklist_id: parentItemId,
                    selected_option: multipleChoice[parentItemId],
                    user_email: userEmail
                });
            }

            await addClientLog({
                user: userEmail,
                clientId: clientData.client._id,
                action: `Submitted ${checklistItem.item_name}`
            });

            // Update the completion info in the state
            const updatedClientData = { ...clientData };
            const updatedChecklists = updatedClientData.checklists.map(item => {
                if (item._id === checklistId) {
                    const editedInfo = {
                        edited_at: new Date().toISOString(),
                        edited_by: userEmail
                    };

                    if (item.completed_info?.completed_at) {
                        return {
                            ...item,
                            edited_info: item.edited_info ? [...item.edited_info, editedInfo] : [editedInfo]
                        };
                    } else {
                        return {
                            ...item,
                            completed_info: {
                                completed_at: new Date().toISOString(),
                                completed_by: userEmail
                            }
                        };
                    }
                }
                return item;
            });
            updatedClientData.checklists = updatedChecklists;
            setClientData({ ...updatedClientData });

            const updatedConditionalItems = { ...conditionalItems };
            if (updatedConditionalItems[checklistId]) {
                updatedConditionalItems[checklistId] = {
                    ...updatedConditionalItems[checklistId],
                    completed_info: {
                        completed_at: new Date().toISOString(),
                        completed_by: userEmail
                    },
                    edited_info: updatedConditionalItems[checklistId].edited_info ? [...updatedConditionalItems[checklistId].edited_info, {
                        edited_at: new Date().toISOString(),
                        edited_by: userEmail
                    }] : [{
                        edited_at: new Date().toISOString(),
                        edited_by: userEmail
                    }]
                };
            }
            updateConditionalItems(updatedConditionalItems);

            setMessage('Multiple text submitted successfully');
            setUnsavedChanges(false);
        } catch (error) {
            setMessage('Error submitting multiple text');
        }
    };




    const handleChecklistTextChange = (checklistId, index, field, value) => {
        setChecklistText(prevChecklistText => {
            const updatedChecklistText = { ...prevChecklistText };
            if (!updatedChecklistText[checklistId]) {
                updatedChecklistText[checklistId] = [];
            }
            if (!updatedChecklistText[checklistId][index]) {
                updatedChecklistText[checklistId][index] = { option: '', value: '', checked: false };
            }
            if (field === 'checked' && !value) {
                updatedChecklistText[checklistId][index].value = '';
            }
            updatedChecklistText[checklistId][index][field] = value;

            setIsEditing(prevIsEditing => ({ ...prevIsEditing, [checklistId]: true }));
            setUnsavedChanges(true);
            return updatedChecklistText;
        });
    };

    const handleConnectIframeSubmit = async (checklistId) => {
        try {
            const checklistItem = clientData.checklists.find(item => item._id === checklistId);
            if (checklistItem.locked && checklistItem.completed_info?.completed_at) {
                handleLockedItem();
                return;
            }

            // Submit the connect_iframe data to the backend
            await submitConnectIframe({
                checklist_id: checklistId,
                user_email: userEmail
            });

            await addClientLog({
                user: userEmail,
                clientId: clientData.client._id,
                action: `Submitted ${checklistItem.item_name}`
            });

            // Update the completion info in the state
            const updatedClientData = { ...clientData };
            const updatedChecklists = updatedClientData.checklists.map(item => {
                if (item._id === checklistId) {
                    const editedInfo = {
                        edited_at: new Date().toISOString(),
                        edited_by: userEmail
                    };

                    if (item.completed_info?.completed_at) {
                        return {
                            ...item,
                            edited_info: item.edited_info ? [...item.edited_info, editedInfo] : [editedInfo]
                        };
                    } else {
                        return {
                            ...item,
                            completed_info: {
                                completed_at: new Date().toISOString(),
                                completed_by: userEmail
                            }
                        };
                    }
                }
                return item;
            });
            updatedClientData.checklists = updatedChecklists;
            setClientData({ ...updatedClientData });

            const updatedConditionalItems = { ...conditionalItems };
            if (updatedConditionalItems[checklistId]) {
                updatedConditionalItems[checklistId] = {
                    ...updatedConditionalItems[checklistId],
                    completed_info: {
                        completed_at: new Date().toISOString(),
                        completed_by: userEmail
                    },
                    edited_info: updatedConditionalItems[checklistId].edited_info ? [...updatedConditionalItems[checklistId].edited_info, {
                        edited_at: new Date().toISOString(),
                        edited_by: userEmail
                    }] : [{
                        edited_at: new Date().toISOString(),
                        edited_by: userEmail
                    }]
                };
            }
            updateConditionalItems(updatedConditionalItems);

            setMessage('Connect iframe submitted successfully');
            setUnsavedChanges(false);
        } catch (error) {
            setMessage('Error submitting connect iframe');
        }
    };



    const handleChecklistTextSubmit = async (checklistId) => {
        try {
            const checklistItem = clientData.checklists.find(item => item._id === checklistId);
            if (checklistItem.locked && checklistItem.completed_info?.completed_at) {
                handleLockedItem();
                return;
            }
            await submitChecklistText({
                checklist_id: checklistId,
                checklist_text_values: checklistText[checklistId],
                user_email: userEmail
            });
            await addClientLog({
                user: userEmail,
                clientId: clientData.client._id,
                action: `Submitted ${checklistItem.item_name}`
            });
            setMessage('Checklist text submitted successfully');
            fetchClientData();
            setUnsavedChanges(false);
        } catch (error) {
            setMessage('Error submitting checklist text');
        }
    };



    const handleDeleteSection = async (sectionName) => {
        //eventually add logic to delete a section if an item name matches the deleted section
        const confirmDelete = window.confirm(`Are you sure you want to delete the section "${sectionName}" and all its questions? This action cannot be undone.`);
        if (confirmDelete) {
            try {
                const encodedSectionName = encodeURIComponent(sectionName);
                await deleteSection(clientData.client._id, encodedSectionName);
                await logClientAction(`Deleted "${sectionName}" section`);

                fetchClientData(); // Refresh the data after deletion
                toast.success(`Section "${sectionName}" deleted successfully.`);
                setUnsavedChanges(false);
            } catch (error) {
                toast.error(`Error deleting section "${sectionName}".`);
                console.error('Error deleting section:', error);
            }
        }
    };


    const handleDeleteChecklistItem = async (checklistId) => {
        const item = clientData.checklists.find(item => item._id === checklistId);
        const sectionName = item?.item_name.split(' - ')[0];
        const itemName = item?.item_name.split(' - ')[1];

        if (groupedChecklists[sectionName].length <= 1) {
            toast.error(`Cannot delete the last item in section "${sectionName}". There must be at least one item in ${sectionName}`);
            return;
        }

        const confirmDelete = window.confirm(`Are you sure you want to delete this question? This action cannot be undone.`);
        if (confirmDelete) {
            try {
                await deleteChecklistItem(checklistId);
                await logClientAction(`Deleted "${itemName}" from ${sectionName}`);
                fetchClientData(); // Refresh the data after deletion
                toast.success('Question deleted successfully.');
                setUnsavedChanges(false);
            } catch (error) {
                toast.error('Error deleting question.');
                console.error('Error deleting question:', error);
            }
        }
    };


    const calculateDaysUntilDue = (dueDate) => {
        const currentDate = new Date();
        const due = new Date(dueDate);
        const timeDiff = due - currentDate;
        return Math.ceil(timeDiff / (1000 * 3600 * 24));
    };

    const formatDate = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear().toString().slice(-2)}`;
    };

    const calculateProgress = () => {
        const totalItems = clientData.checklists?.length || 0;
        if (totalItems === 0) return 0;
        return (completedItems / totalItems) * 100;
    };

    const calculateSectionProgress = (section, groupedChecklists) => {
        const sectionItems = (groupedChecklists[section] || []).filter(item => item.type !== 'contact_info');
        if (sectionItems.length === 0) return 0;

        const optionalItems = sectionItems.filter(item => !item.optional);
        if (optionalItems.length === 0) return 0;

        const completedSectionItems = optionalItems.filter(item =>
            item.type === 'text' ? item.completed_info?.completed_at :
                item.type === 'business_hours' ? item.completed_info?.completed_at :
                    item.type === 'multiple_choice' ? item.completed_info?.completed_at :
                        item.type === 'checkboxes' ? item.completed_info?.completed_at :
                            item.type === 'contact_info' ? item.completed_info?.completed_at :
                                item.type === 'multiple_text' ? item.completed_info?.completed_at :
                                    item.type === 'ad_schedule' ? item.completed_info?.completed_at :
                                        item.type === 'checklist_text' ? item.checklist_text_values.some(v => v.checked && v.value?.trim() && item.completed_info?.completed_at) :
                                            item.type === 'connect_iframe' ? item.completed_info?.completed_at :  // New condition for connect_iframe
                                                isDocumentUploaded(item._id)
        ).length;

        return (completedSectionItems / optionalItems.length) * 100;
    };




    const isSectionCompleted = (section, groupedChecklists) => {
        const dueDateInfo = clientData.client?.due_dates?.find(d => d.section === section);
        if (dueDateInfo?.completed === true) {
            return true;
        }


        const completed = groupedChecklists[section]
            .filter(item => !item.optional) // Filter out optional items
            .every(item =>
                item.type === 'text' ? item.completed_info?.completed_at :
                    item.type === 'business_hours' ? item.completed_info?.completed_at :
                        item.type === 'multiple_choice' ? item.completed_info?.completed_at :
                            item.type === 'checkboxes' ? item.completed_info?.completed_at :
                                item.type === 'contact_info' ? item.completed_info?.completed_at :
                                    item.type === 'multiple_text' ? item.completed_info?.completed_at :
                                        item.type === 'ad_schedule' ? item.completed_info?.completed_at :
                                            item.type === 'checklist_text' ? item.checklist_text_values.some(v => v.checked && v.value?.trim() && item.completed_info?.completed_at) :
                                                item.type === 'connect_iframe' ? item.completed_info?.completed_at :  // New condition for connect_iframe
                                                    isDocumentUploaded(item._id)
            );

        const clientID = clientData.client?._id; // Assuming you have clientData available in the scope
        const confettiKey = `confetti_${clientID}_${section}`;
        const confettiPlayed = JSON.parse(localStorage.getItem(confettiKey));

        if (completed && !confettiPlayed) {
            const end = Date.now() + (3 * 1000); // Play confetti for 3 seconds
            const colors = ['#bb00bb', '#660066', '#ff66ff', '#cc33cc', '#990099'];

            (function frame() {
                confetti({
                    particleCount: 2,
                    angle: 60,
                    spread: 55,
                    origin: { x: 0 },
                    colors: colors
                });
                confetti({
                    particleCount: 2,
                    angle: 120,
                    spread: 55,
                    origin: { x: 1 },
                    colors: colors
                });

                if (Date.now() < end) {
                    requestAnimationFrame(frame);
                }
            })();

            setConfettiDisplayed(prevState => ({
                ...prevState,
                [section]: true
            }));

            // Save the confetti state to local storage
            localStorage.setItem(confettiKey, JSON.stringify(true));

            markSectionAsCompleted(clientID, section, clientData.client.name, `https://onboarding.ferociousmedia.com/admin/client-portal/${url}`, clientData.client.ferociousEmailDistro);
        }

        return completed;
    };




    const handleSectionToggle = (section) => {
        setOpenSection(openSection === section ? null : section);
    };

    const adjustTextareaHeight = (textarea) => {
        textarea.rows = Math.max(1, Math.floor(textarea.scrollHeight / 20)); // Assuming 20px per line height
    };

    useEffect(() => {
        if (openSection) {
            const textareas = document.querySelectorAll('textarea');
            textareas.forEach(adjustTextareaHeight);
        }
    }, [openSection]);

    const handleAddNewSection = async () => {
        try {
            if (selectedDefaultSection) {
                const defaultSection = defaultSections.find(section => section.name === selectedDefaultSection);
                if (defaultSection) {
                    for (const item of defaultSection.items) {
                        const newItem = {
                            client_id: clientData.client._id,
                            item_name: `${defaultSection.name} - ${item.name}`,
                            type: item.type,
                            description: item.description,
                            options: item.options,
                            locked: item.locked,
                            text_value: item.text_value || '',
                            business_hours: item.business_hours || {},
                            selected_option: item.selected_option || '',
                            selected_options: item.selected_options || [],
                            multiple_text_values: item.multiple_text_values || [],
                            contact_info: item.contact_info || [],
                            completed_info: {},
                            edited_info: [],
                            optional: item.optional || false,
                            conditional_items: item.conditional_items ? item.conditional_items.map(condItem => ({
                                show_on: condItem.show_on,
                                item_id: condItem.item_id,
                                item_name: condItem.item_name,
                                item_type: condItem.item_type,
                                item_description: condItem.item_description
                            })) : [] // Ensure conditional_items is properly initialized
                        };
                        await addChecklistItem(clientData.client._id, newItem);
                    }
                    await logClientAction(`${userEmail} added section "${defaultSection.name}"`);
                }
            } else if (newSectionName && newSectionDueDate) {
                const newSection = {
                    item_name: `${newSectionName} - Temporary Item Please Delete`,
                    due_date: newSectionDueDate,
                    client_id: clientData.client._id
                };
                await addChecklistItem(clientData.client._id, newSection);
            }
            setShowNewSectionModal(false);
            fetchClientData();
            toast.success('Section added successfully');
        } catch (error) {
            console.error('Error adding new section:', error);
            toast.error('Error adding new section');
        }
    };

    const handleAddNewChecklistItem = (sectionName) => {
        setNewItemName('');
        setNewItemType('');
        setNewItemOptions('');
        setNewItemOptional(false); // Reset optional checkbox state
        setNewItemLocked(false); // Reset locked checkbox state
        setShowAddItemModal(true);
    };

    const handleNewItemSubmit = async () => {
        if (!newItemName || !newItemType) {
            toast.error('Please enter both a name and type for the new item.');
            return;
        }

        try {
            const newItem = {
                client_id: clientData.client._id,
                item_name: `${openSection} - ${newItemName}`,
                type: newItemType,
                description: '',
                options: (newItemType === 'multiple_choice' || newItemType === 'checkboxes') ? newItemOptions.split('\n') : [],
                locked: newItemLocked,
                text_value: '',
                business_hours: {},
                selected_option: '',
                selected_options: [],
                multiple_text_values: [],
                contact_info: [],
                completed_info: {},
                edited_info: [],
                optional: newItemOptional,
                conditional_items: [],
                text_input_type: (newItemType === 'text' || newItemType === 'multiple_text') ? newItemTextType : ''
            };

            await addChecklistItem(clientData.client._id, newItem);
            await logClientAction(`${userEmail} added "${newItemName}" to ${openSection}`);
            setShowAddItemModal(false);
            fetchClientData();
            toast.success('Checklist item added successfully');
        } catch (error) {
            console.error('Error adding new checklist item:', error);
            toast.error('Error adding new checklist item');
        }
    };


    const getSubmittedFiles = (checklistId) => {
        return clientData.documents?.filter(doc => doc.checklist_id === checklistId) || [];
    };

    const handleDeleteDocument = async (documentId) => {
        try {
            // Add your delete document logic here, for example:
            await deleteDocument(documentId); // Ensure you have a deleteDocument API call set up
            setMessage('Document deleted successfully');
            fetchClientData();
        } catch (error) {
            setMessage('Error deleting document');
        }
    };

    const groupedChecklists = clientData.checklists?.reduce((acc, item) => {
        if (!item.parent_item_id) {
            const [section, itemName] = item.item_name.split(' - ');
            if (!acc[section]) acc[section] = [];
            acc[section].push({ ...item, itemName });
        }
        return acc;
    }, {}) || {};

    const existingSections = Object.keys(groupedChecklists);

    const filteredDefaultSections = defaultSections.filter(section => !existingSections.includes(section.name));

    const renderConditionalItems = (parentId) => {
        const seenConditionalItems = new Set(); // Track unique items

        return Object.values(conditionalItems)
            .filter(item => {
                const parentItem = clientData.checklists.find(ci => ci._id === item.parent_id);

                if (!parentItem) {
                    console.warn(`No parent item found for conditional item with ID: ${item._id}`);
                    return false;
                }

                console.log("parentItem:", parentItem);
                console.log("parentItem.type:", parentItem.type || 'undefined');

                let shouldShow = false;
                if (parentItem.type === 'multiple_choice') {
                    // Ensure only the parent that owns the item is triggering it
                    shouldShow = multipleChoice[parentItem._id] === item.show_on && parentId === parentItem._id;
                } else if (parentItem.type === 'checkboxes') {
                    shouldShow = checkboxes[parentItem._id]?.includes(item.show_on) && parentId === parentItem._id;
                }


                // Ensure item is only included once
                if (shouldShow && !seenConditionalItems.has(item._id)) {
                    seenConditionalItems.add(item._id);
                    return true;
                }
                return false;
            })
            .map(condItem => {
                return (
                    <div key={condItem._id} className="question-item conditional-item">
                        <div className="item-flex-container">
                            <div className="item-container">
                                <div className="item-name-container" style={{ marginLeft: condItem.completed_info?.completed_at ? '-28px' : '0' }}>
                                    {condItem.completed_info?.completed_at && <img src={checkIcon} alt="Completed" className='completed-checkmark' />}
                                    <strong className="item-name">{condItem.item_name}</strong>
                                </div>
                                <p className="item-description">{condItem.description}</p>
                            </div>
                            <div className="item-input">
                                {condItem.type === 'text' && (
                                    <div className="input-container">
                                        {condItem.text_input_type === 'plain' ? (
                                            <div className="quill-container">
                                                <div className="quill-wrapper">
                                                    <ReactQuill
                                                        value={text[condItem._id] || ''}
                                                        onChange={(value) => handleTextChange(condItem._id)({ target: { value } })}
                                                        modules={{ toolbar: `#toolbar-${condItem._id}` }}
                                                        placeholder="Text Input"
                                                    />
                                                    {isEditing[condItem._id] && <button className="button" onClick={() => handleTextSubmit(condItem._id)}>Submit</button>}
                                                </div>
                                                <div
                                                    id={`toolbar-${condItem._id}`}
                                                    className={`ql-toolbar ql-snow toolbar ${isEditing[condItem._id] ? 'visible' : 'hidden'}`}
                                                >
                                                    <span className="ql-formats">
                                                        <button className="ql-header" value="1"></button>
                                                        <button className="ql-header" value="2"></button>
                                                        <button className="ql-bold"></button>
                                                        <button className="ql-italic"></button>
                                                    </span>
                                                    <span className="ql-formats">
                                                        <button className="ql-list" value="ordered"></button>
                                                        <button className="ql-list" value="bullet"></button>
                                                    </span>
                                                    <span className="ql-formats">
                                                        <button className="ql-link"></button>
                                                        <button className="ql-image"></button>
                                                    </span>
                                                    <span className="ql-formats">
                                                        <button className="ql-clean"></button>
                                                    </span>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="textarea-wrapper">
                                                <textarea
                                                    value={text[condItem._id] || ''}
                                                    onChange={handleTextChange(condItem._id)}
                                                    placeholder={
                                                        condItem.text_input_type === 'phone' ? 'Phone Input' :
                                                            condItem.text_input_type === 'email' ? 'Email Input' :
                                                                'Text Input'
                                                    }
                                                    type={
                                                        condItem.text_input_type === 'phone' ? 'tel' :
                                                            condItem.text_input_type === 'email' ? 'email' :
                                                                'text'
                                                    }
                                                    rows={1}
                                                />
                                                {isEditing[condItem._id] && <button className="button" onClick={() => handleTextSubmit(condItem._id)}>Submit</button>}
                                            </div>
                                        )}
                                    </div>
                                )}
                                {condItem.type === 'file' && (
                                    <>
                                        <p className='fileInstructions'>Accepted file types: .txt, .pdf, .png, .jpg, .jpeg, .gif, .bmp, .webp, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .zip, .mp3, .mp4, .avi, .mov</p>
                                        <p className='fileInstructions'>If your file is over 750 MB, is not an accepted file type, or you are having difficulties uploading, please email files to {clientData.client?.ferociousEmailDistro}</p>
                                        {getSubmittedFiles(condItem._id).map((file) => (
                                            <div key={file._id} className='fileContainer' style={{ display: 'flex', alignItems: 'center' }}>
                                                <a href={file.file_url} target="_blank" rel="noopener noreferrer" download>{file.file_name || 'Download Document'}</a>
                                                <span
                                                    className="deleteDocBtn material-icons"
                                                    style={{ marginLeft: '10px', fontSize: '15px', fontWeight: '600', cursor: 'pointer' }}
                                                    onClick={() => handleDeleteDocument(file._id)}
                                                >
                                                    close
                                                </span>
                                            </div>
                                        ))}
                                        {files[condItem._id] && Object.keys(files[condItem._id]).map((fileId) => (
                                            <div key={fileId} className='contact-input-container'>
                                                <input
                                                    className='fileUpload'
                                                    id={`fileInput-${condItem._id}-${fileId}`}
                                                    type="file"
                                                    onChange={handleFileChange(condItem._id, fileId)}
                                                    multiple
                                                />
                                                <label className="custom-file-upload" htmlFor={`fileInput-${condItem._id}-${fileId}`}>
                                                    <span className="material-symbols-outlined">upload</span>
                                                    Choose File
                                                </label>
                                                <div className="file-list">
                                                    {files[condItem._id][fileId] && files[condItem._id][fileId].map((file, index) => (
                                                        <div key={`${fileId}-${index}`} className='file-action-wrapper'>
                                                            <span className={`file-name ${file ? 'visible' : ''}`}>{file.name}</span>
                                                            {progress[condItem._id] && progress[condItem._id][fileId] && progress[condItem._id][fileId][index] !== undefined ? (
                                                                <div className="upload-progress-container">
                                                                    <div className="upload-progress-bar">
                                                                        <div className="upload-progress" style={{ width: `${progress[condItem._id][fileId][index]}%` }}></div>
                                                                    </div>
                                                                    <span className="progress-percentage">
                                                                        {progress[condItem._id][fileId][index] === 100 ? 99 : progress[condItem._id][fileId][index]}%
                                                                    </span>
                                                                </div>
                                                            ) : (
                                                                <button className="uploadBtn" onClick={() => handleUpload(condItem._id, fileId, index)} disabled={!file}>Submit Document</button>
                                                            )}
                                                            <button className="deleteBtn" onClick={() => handleDeleteFileInput(condItem._id, fileId, index)}>Delete</button>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        ))}
                                        <button className="button" onClick={() => handleAddFileInput(condItem._id)}>
                                            {getSubmittedFiles(condItem._id).length > 0 || (files[condItem._id] && Object.keys(files[condItem._id]).length > 0) ? "+ Add Additional File" : "+ Add File"}
                                        </button>
                                    </>
                                )}

                                {condItem.type === 'multiple_choice' && (
                                    <div className="multiple-choice-container">
                                        {condItem.options.map(option => (
                                            <div key={option} className="multiple-choice-option">
                                                <input
                                                    type="radio"
                                                    id={`${condItem._id}-${option}`}
                                                    name={condItem._id}
                                                    value={option}
                                                    checked={multipleChoice[condItem._id] === option}
                                                    onChange={handleMultipleChoiceChange(condItem._id)}
                                                />
                                                <label htmlFor={`${condItem._id}-${option}`}>{option}</label>

                                            </div>
                                        ))}
                                        {isEditing[condItem._id] && (
                                            <>
                                                <button className="button" onClick={() => handleMultipleChoiceSubmit(condItem._id)}>Submit</button>
                                            </>
                                        )}
                                    </div>
                                )}

                                {/* ✅ Handle checkboxes type */}
                                {condItem.type === 'checkboxes' && (
                                    <div className="checkbox-container">
                                        {condItem.options.map(option => (
                                            <div key={option} className="checkbox-option">
                                                <input
                                                    type="checkbox"
                                                    id={`${condItem._id}-${option}`}
                                                    name={condItem._id}
                                                    value={option}
                                                    checked={checkboxes[condItem._id]?.includes(option) || false}
                                                    onChange={handleCheckboxChange(condItem._id, option)}
                                                />
                                                <label htmlFor={`${condItem._id}-${option}`}>{option}</label>
                                            </div>
                                        ))}
                                        {isEditing[condItem._id] && (
                                            <>
                                                <button className="button" onClick={() => handleCheckboxSubmit(condItem._id)}>Submit</button>
                                            </>
                                        )}
                                    </div>
                                )}
                                {condItem.type === 'business_hours' && (
                                    <div className="business-hours-container">
                                        <table className="business-hours-table">
                                            <thead>
                                                <tr>
                                                    <th>Day</th>
                                                    <th>Opening Time</th>
                                                    <th>Closing Time</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'].map(day => (
                                                    <tr key={day}>
                                                        <td>{day.charAt(0).toUpperCase() + day.slice(1)}</td>
                                                        <td>
                                                            <select
                                                                value={businessHours[condItem._id]?.[day]?.open || ''}
                                                                onChange={(e) => handleBusinessHoursChange(condItem._id, day, 'open')(e)}
                                                            >
                                                                <option value="">Select Opening Time</option>
                                                                <option value="Closed">Closed</option>
                                                                <option value="24HR">24 Hours</option>
                                                                {Array.from({ length: 24 }, (_, hour) => (
                                                                    <>
                                                                        <option key={`${hour}-00`} value={`${hour % 12 || 12}:00 ${hour < 12 ? 'AM' : 'PM'}`}>
                                                                            {`${hour % 12 || 12}:00 ${hour < 12 ? 'AM' : 'PM'}`}
                                                                        </option>
                                                                        <option key={`${hour}-30`} value={`${hour % 12 || 12}:30 ${hour < 12 ? 'AM' : 'PM'}`}>
                                                                            {`${hour % 12 || 12}:30 ${hour < 12 ? 'AM' : 'PM'}`}
                                                                        </option>
                                                                    </>
                                                                ))}
                                                            </select>
                                                        </td>
                                                        <td>
                                                            <select
                                                                value={businessHours[condItem._id]?.[day]?.close || ''}
                                                                onChange={(e) => handleBusinessHoursChange(condItem._id, day, 'close')(e)}
                                                                disabled={businessHours[condItem._id]?.[day]?.open === 'Closed' || businessHours[condItem._id]?.[day]?.open === '24HR'}
                                                            >
                                                                <option value="">Select Closing Time</option>
                                                                {getEndTimes(businessHours[condItem._id]?.[day]?.open || '').map((time, idx) => (
                                                                    <option key={idx} value={time}>{time}</option>
                                                                ))}
                                                            </select>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        {isEditing[condItem._id] && <button className="button" onClick={() => handleBusinessHoursSubmit(condItem._id)}>Submit</button>}
                                    </div>
                                )}
                                {condItem.type === 'contact_info' && (
                                    <>
                                        {contactInfo[condItem._id]?.map((contact, index) => (
                                            <div key={index} className="contact-input-container">
                                                <input
                                                    type="text"
                                                    value={contact.contactName || ''}
                                                    onChange={handleContactInfoChange(condItem._id, index, 'contactName')}
                                                    placeholder="Contact Name"
                                                />
                                                <input
                                                    type="email"
                                                    value={contact.contactEmail || ''}
                                                    onChange={handleContactInfoChange(condItem._id, index, 'contactEmail')}
                                                    placeholder="Contact Email"
                                                />
                                                <input
                                                    type="tel"
                                                    value={contact.contactPhone || ''}
                                                    onChange={handleContactInfoChange(condItem._id, index, 'contactPhone')}
                                                    placeholder="Contact Phone"
                                                />
                                                {isEditing[condItem._id] && <button className="button" onClick={() => handleContactInfoSubmit(condItem._id, index)}>Submit</button>}
                                                <button className="deleteBtn" onClick={() => handleDeleteContactInfo(condItem._id, index)}>Delete</button>
                                            </div>
                                        ))}
                                        <button className="button" onClick={() => handleAddContactInfo(condItem._id)}>+ Add Another Contact</button>
                                    </>
                                )}
                                {condItem.type === 'multiple_text' && (
                                    <>
                                        {multipleText[condItem._id]?.map((textValue, index) => (
                                            <div key={index} className="contact-input-container">
                                                <textarea
                                                    value={textValue || ''}
                                                    onChange={handleMultipleTextChange(condItem._id, index)}
                                                    placeholder={
                                                        condItem.text_input_type === 'phone' ? 'Phone Input' :
                                                            condItem.text_input_type === 'email' ? 'Email Input' :
                                                                'Text Input'
                                                    }
                                                    type={
                                                        condItem.text_input_type === 'phone' ? 'tel' :
                                                            condItem.text_input_type === 'email' ? 'email' :
                                                                'text'
                                                    }
                                                    rows={1}
                                                />
                                                {isEditing[condItem._id] && <button className="button" onClick={() => handleMultipleTextSubmit(condItem._id)}>Submit</button>}
                                                <button className="deleteBtn" onClick={() => handleDeleteMultipleText(condItem._id, index)}>Delete</button>
                                            </div>
                                        ))}
                                        <button className="button" onClick={() => handleAddMultipleText(condItem._id)}>+ Add Another</button>
                                    </>
                                )}
                                {condItem.type === 'ad_schedule' && (
                                    <div className="adScheduleContainer">
                                        <table className="adScheduleTable">
                                            <thead>
                                                <tr>
                                                    <th>Day of Week</th>
                                                    <th>Run Ads?</th>
                                                    <th>Start Time</th>
                                                    <th>End Time</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map((day, index) => (
                                                    <tr key={index}>
                                                        <td>{day}</td>
                                                        <td>
                                                            <input
                                                                type="checkbox"
                                                                checked={adSchedule[condItem._id]?.[index]?.runAds || false}
                                                                onChange={(e) => handleAdScheduleChange(condItem._id, index, 'runAds')(e)}
                                                            />
                                                        </td>
                                                        <td>
                                                            <select
                                                                value={adSchedule[condItem._id]?.[index]?.startTime || ''}
                                                                onChange={(e) => handleAdScheduleChange(condItem._id, index, 'startTime')(e)}
                                                                disabled={!adSchedule[condItem._id]?.[index]?.runAds}
                                                            >
                                                                <option value="">Select Start Time</option>
                                                                <option value="24 Hours">24 Hours</option>
                                                                {Array.from({ length: 24 }, (_, hour) => (
                                                                    <>
                                                                        <option key={`${hour}-00`} value={`${hour % 12 || 12}:00 ${hour < 12 ? 'AM' : 'PM'}`}>
                                                                            {`${hour % 12 || 12}:00 ${hour < 12 ? 'AM' : 'PM'}`}
                                                                        </option>
                                                                        <option key={`${hour}-30`} value={`${hour % 12 || 12}:30 ${hour < 12 ? 'AM' : 'PM'}`}>
                                                                            {`${hour % 12 || 12}:30 ${hour < 12 ? 'AM' : 'PM'}`}
                                                                        </option>
                                                                    </>
                                                                ))}
                                                            </select>
                                                        </td>
                                                        <td>
                                                            <select
                                                                value={adSchedule[condItem._id]?.[index]?.endTime || ''}
                                                                onChange={(e) => handleAdScheduleChange(condItem._id, index, 'endTime')(e)}
                                                                disabled={!adSchedule[condItem._id]?.[index]?.runAds || adSchedule[condItem._id]?.[index]?.startTime === '24 Hours'}
                                                            >
                                                                <option value="">Select End Time</option>
                                                                {getEndTimes(adSchedule[condItem._id]?.[index]?.startTime || '').map((time, idx) => (
                                                                    <option key={idx} value={time}>{time}</option>
                                                                ))}
                                                            </select>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        {isEditing[condItem._id] && <button className="button" onClick={() => handleAdScheduleSubmit(condItem._id)}>Submit</button>}
                                    </div>
                                )}
                                {condItem.type === 'checklist_text' && (
                                    <>
                                        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                                            <tbody>
                                                {condItem.options.map((option, index) => (
                                                    <tr key={index} style={{ borderBottom: '1px solid transparent' }}>
                                                        <td style={{ padding: '8px', width: '40px' }}>
                                                            <div className="checkbox-option">
                                                                <input
                                                                    type="checkbox"
                                                                    id={`${condItem._id}-${index}`}
                                                                    name={`${condItem._id}-${index}`}
                                                                    checked={checklistText[condItem._id]?.[index]?.checked || false}
                                                                    onChange={(e) => handleChecklistTextChange(condItem._id, index, 'checked', e.target.checked)}
                                                                />
                                                                <label htmlFor={`${condItem._id}-${index}`}></label>
                                                            </div>
                                                        </td>
                                                        <td style={{ padding: '8px' }}>
                                                            <label htmlFor={`${condItem._id}-${index}`} style={{ marginRight: '8px' }}>{option}</label>
                                                        </td>
                                                        <td style={{ padding: '8px', width: '100%' }}>
                                                            <input
                                                                type="text"
                                                                value={checklistText[condItem._id]?.[index]?.value || ''}
                                                                onChange={(e) => handleChecklistTextChange(condItem._id, index, 'value', e.target.value)}
                                                                placeholder="Text Input"
                                                                style={{
                                                                    width: '100%',
                                                                    backgroundColor: checklistText[condItem._id]?.[index]?.checked ? 'white' : '#e0e0e0',
                                                                    cursor: checklistText[condItem._id]?.[index]?.checked ? 'text' : 'not-allowed'
                                                                }}
                                                                disabled={!checklistText[condItem._id]?.[index]?.checked}
                                                            />
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        {isEditing[condItem._id] && <button className="button" onClick={() => handleChecklistTextSubmit(condItem._id)}>Submit</button>}
                                    </>
                                )}
                                {condItem.completed_info?.completed_at && (
                                    <span className='completed-item-text'>Completed on: {formatDate(condItem.completed_info.completed_at)} by {condItem.completed_info.completed_by}</span>
                                )}
                                {condItem.edited_info?.map((edit, index) => (
                                    <span key={index} className='edited-item-text'>Edited on: {formatDate(edit.edited_at)} by {edit.edited_by}</span>
                                ))}
                            </div>
                        </div>
                    </div>
                );
            });
    };

    const sortSections = (sections) => {
        return sections.sort((a, b) => {
            const dueDateA = clientData.client?.due_dates?.find(d => d.section === a)?.due_date || '';
            const dueDateB = clientData.client?.due_dates?.find(d => d.section === b)?.due_date || '';

            const isCompletedA = isSectionCompleted(a, groupedChecklists);
            const isCompletedB = isSectionCompleted(b, groupedChecklists);

            if (isCompletedA && !isCompletedB) {
                return 1;
            } else if (!isCompletedA && isCompletedB) {
                return -1;
            }

            if (dueDateA && dueDateB) {
                const dateComparison = new Date(dueDateA) - new Date(dueDateB);
                if (dateComparison !== 0) {
                    return dateComparison;
                } else {
                    return a.localeCompare(b);
                }
            } else if (dueDateA) {
                return -1;
            } else if (dueDateB) {
                return 1;
            } else {
                return a.localeCompare(b);
            }
        });
    };

    const sortedSections = sortSections(Object.keys(groupedChecklists));

    const handleSaveChanges = async () => {
        toast.info('Saving changes...');
        // Perform save operations
        // Example:
        try {
            // Simulate saving changes without actually submitting items
            await new Promise(resolve => setTimeout(resolve, 1000)); // Simulate a delay for the save operation
            toast.success('Changes saved successfully.');
            setUnsavedChanges(false);
        } catch (error) {
            toast.error('Failed to save changes.');
        }
    };


    const handleToggleEditMode = async () => {
        if (userType !== 'Admin' && userType !== 'Super Admin') {
            toast.error('You do not have permission to enter edit mode.');
            return;
        }

        if (editMode && unsavedChanges) {
            if (window.confirm('You have unsaved changes. Do you want to save them before exiting edit mode?')) {
                await handleSaveChanges();
                await handleSaveDescriptions();
                await handleSaveSectionNames();
            }
        }

        if (editMode) {
            await Promise.all([
                ...Object.keys(itemNames).map(async itemId => {
                    const newName = itemNames[itemId];
                    const existingItem = clientData.checklists.find(item => item._id === itemId);
                    if (existingItem && existingItem.item_name !== newName) {
                        const [sectionName, oldItemName] = existingItem.item_name.split(' - ');
                        const fullItemName = `${sectionName} - ${newName}`;
                        try {
                            await updateItemName({ checklist_id: itemId, item_name: fullItemName, user_email: userEmail });
                            await logClientAction(`Changed item "${oldItemName}" to "${newName}" in ${sectionName}`);
                        } catch (error) {
                            console.error(`Failed to update item name for item ID ${itemId}:`, error);
                        }
                    }
                }),
                ...Object.keys(itemDescriptions).map(async itemId => {
                    const newDescription = itemDescriptions[itemId];
                    const existingItem = clientData.checklists.find(item => item._id === itemId);
                    if (existingItem && existingItem.description !== newDescription) {
                        try {
                            await updateItemDescription({ checklist_id: itemId, description: newDescription, user_email: userEmail });
                        } catch (error) {
                            console.error(`Failed to update description for item ID ${itemId}:`, error);
                        }
                    }
                })
            ]);
            await fetchClientData();
            console.log('Client data fetched successfully.');
        }

        setEditMode(!editMode);
    };






    return (
        <div className="client-view">
            <NavBar userEmail={userEmail} clientName={clientData.client?.name} clientUrl={clientData.client?._id} userName={userName} />
            <div className="hero">
                <div className="hollow-circle"></div>
                <div className="hollow-circle-bottom"></div>
                <div className="hollow-circle-right"></div>
                <div className="hollow-circle-top"></div>
                <div className="circle"></div>
                <div className="circle2"></div>
                <div className="dot-array"></div>
                <div className="dot-array2"></div>
                <div className="hero-content">
                    <div className="title">
                        <div className="title_container">
                            <h1><span className='welcomeText'>Welcome To Ferocious,</span><br />
                                <span className="secondaryText">{clientData.client?.name}!</span></h1>
                        </div>
                    </div>
                    <div className="body-text">
                        <p>
                            In order to make the transition to Ferocious as simple and seamless as possible, we have put together this onboarding checklist specifically for {clientData.client?.name}. Please work through the items below and complete as many as possible.
                        </p>
                        <p>
                            This onboarding checklist is designed to help {clientData.client?.name} easily transition to Ferocious. Complete as many items as you can, and feel free to save your progress and return later.
                        </p>
                        <p>
                            If you have any questions, please email <a href={`mailto:${clientData.client?.ferociousEmailDistro}`}>{clientData.client?.ferociousEmailDistro}</a> or CHAT WITH US
                        </p>
                    </div>
                </div>
            </div>
            <div className='instructionsContainer'>
                <h4 className='instructions'>
                    This is an Admin view of the client portal for {clientData.client?.name}. To see the client view{' '}
                    <a href={`/client/${clientData.client?.url}`}>click here</a>
                </h4>
            </div>
            {(userType === 'Admin' || userType === 'Super Admin') && (
                <div className='centerContainer'>
                    <button className='editModeButton' onClick={handleToggleEditMode}>{editMode ? 'Exit Edit Mode' : 'Enter Edit Mode'}</button>
                </div>
            )}

            <div className="checklist-sections">
                {sortedSections.map((section, index) => {
                    const dueDateInfo = clientData.client?.due_dates?.find(d => d.section === section);
                    const sectionCompleted = isSectionCompleted(section, groupedChecklists);
                    const isOpen = openSection === section;
                    const sectionProgress = calculateSectionProgress(section, groupedChecklists);

                    const daysUntilDue = dueDateInfo ? calculateDaysUntilDue(dueDateInfo.due_date) : null;
                    let dueDateStyle = {};
                    if (daysUntilDue !== null) {
                        if (daysUntilDue < 3) {
                            dueDateStyle = { color: 'red' };
                        } else if (daysUntilDue <= 5) {
                            dueDateStyle = { color: '#d1730f' };
                        }
                    }

                    return (
                        <div
                            key={section}
                            className={`card ${isOpen ? 'open selected' : ''} ${sectionCompleted ? 'complete' : ''}`}
                            onClick={() => handleSectionToggle(section)}
                            style={{ animationDelay: `${index * 0.1}s` }} // Add staggered delay
                        >
                            {editMode && (
                                <button
                                    className="delete-section-button"
                                    onClick={(e) => {
                                        e.stopPropagation(); // Prevent the section toggle when clicking the delete button
                                        handleDeleteSection(section);
                                    }}
                                >
                                    <span className="material-icons">close</span>
                                </button>
                            )}
                            <div className="card-content">
                                {sectionCompleted && (
                                    <div className="checkmark-circle">
                                        <span className="material-icons">check</span>
                                    </div>
                                )}
                                {editMode ? (
                                    <>
                                        <input
                                            type="text"
                                            value={sectionNames[section] || section}
                                            onChange={handleSectionNameChange(section)}
                                        />
                                        {!sectionCompleted && (
                                            <input
                                                type="date"
                                                value={formatDateForInput(sectionDueDates[section]) || ''}
                                                onChange={handleDueDateChange(section)}
                                                placeholder="Due Date"
                                            />
                                        )}
                                    </>
                                ) : (
                                    <>
                                        <h3>{section}</h3>
                                        {!sectionCompleted && dueDateInfo && (
                                            <p className="due-date" style={dueDateStyle}>Due: {formatDate(dueDateInfo.due_date)}</p>
                                        )}
                                    </>
                                )}
                                <p className="completion">{sectionProgress.toFixed(2)}% Complete</p>
                                <div className="progress-bar">
                                    <div className="progress" style={{ width: `${sectionProgress}%` }}></div>
                                </div>
                                {sectionCompleted && (
                                    <p className="completed-date">Completed on {formatDate(dueDateInfo?.completion_date || new Date())}</p>

                                )}
                            </div>
                        </div>
                    );
                })}
                {editMode && (
                    <div
                        className="card add-section-card"
                        onClick={() => setShowNewSectionModal(true)}
                    >
                        <div className="card-content">
                            <h3>Add New Section</h3>
                            <div className="add-icon">
                                <span className="material-icons">add</span>
                            </div>
                        </div>
                    </div>
                )}
            </div>


            {openSection && groupedChecklists[openSection] && (
                <div className="section-questions">
                    <h4 className='checklist-header'>{openSection} Questions</h4>
                    <div className="question-list">
                        {groupedChecklists[openSection].flatMap((item) => {
                            if (item.type === 'connect_iframe') {
                                return (
                                    <div key={item._id} className="question-item">
                                        {item.completed_info?.completed_at && (
                                            <span className='completed-item-text'>Completed on: {formatDate(item.completed_info.completed_at)} by {item.completed_info.completed_by}</span>
                                        )}
                                        {item.edited_info?.map((edit, index) => (
                                            <span key={index} className='edited-item-text'>Edited on: {formatDate(edit.edited_at)} by {edit.edited_by}</span>
                                        ))}
                                        <p>Have more than one Google Ads Account?  Please Connect your first one below and then go <b><a target='_blank' href='https://app.admatic.io/#/connect/FerociousLSA'>HERE</a></b> to connect additional accounts, one at a time!</p>

                                        <button className="button" style={{ marginBottom: '10px' }} onClick={() => handleConnectIframeSubmit(item._id)}>Submit</button>
                                        <iframe
                                            src="https://app.admatic.io/#/connect/ferocious"
                                            webkitallowfullscreen=""
                                            mozallowfullscreen=""
                                            allowfullscreen="allowfullscreen"
                                            allow="clipboard-write;camera;microphone;"
                                            style={{ height: "calc(-95px + 100vh)", width: "100%", border: "none" }}
                                        ></iframe>
                                    </div>
                                );
                            }
                            const isOptional = item.optional; // Ensure we are checking the correct property



                            // Extract only the item name
                            const itemName = item.item_name.split(' - ')[1] || item.item_name;

                            const items = [
                                <div key={item._id} className="question-item">
                                    <div className="item-flex-container">
                                        <div className="item-container">
                                            <div className="item-name-container" style={{ marginLeft: item.completed_info?.completed_at ? '-28px' : '0' }}>
                                                {item.completed_info?.completed_at && <img src={checkIcon} alt="Completed" className='completed-checkmark' />}
                                                {editMode ? (
                                                    <textarea
                                                        value={itemNames[item._id] || itemName}
                                                        onChange={handleItemNameChange(item._id)}
                                                        rows={1}
                                                    />
                                                ) : (
                                                    <strong className="item-name">{itemName}{!item.optional && ' *'}</strong>
                                                )}
                                            </div>
                                            {editMode ? (
                                                <textarea
                                                    value={itemDescriptions[item._id] || ''}
                                                    onChange={handleItemDescriptionChange(item._id)}
                                                    placeholder="Item Description"
                                                    rows={1}
                                                />
                                            ) : (
                                                <p className="item-description">{item.description}</p>
                                            )}
                                        </div>
                                        <div className="item-input">
                                            {item.type === 'file' ? (
                                                <>
                                                    <p className='fileInstructions'>Accepted file types: .txt, .pdf, .png, .jpg, .jpeg, .gif, .bmp, .webp, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .zip, .mp3, .mp4, .avi, .mov</p>
                                                    <p className='fileInstructions'>If your file is over 750 MB, is not an accepted file type, or you are having difficulties uploading, please email files to {clientData.client?.ferociousEmailDistro}</p>
                                                    {getSubmittedFiles(item._id).map((file) => (
                                                        <div key={file._id} className='fileContainer' style={{ display: 'flex', alignItems: 'center' }}>
                                                            <a href={file.file_url} target="_blank" rel="noopener noreferrer" download>{file.file_name || 'Download Document'}</a>
                                                            <span
                                                                className="deleteDocBtn material-icons"
                                                                style={{ marginLeft: '10px', fontSize: '15px', fontWeight: '600', cursor: 'pointer' }}
                                                                onClick={() => handleDeleteDocument(file._id)}
                                                            >
                                                                close
                                                            </span>
                                                        </div>
                                                    ))}
                                                    {files[item._id] && Object.keys(files[item._id]).map((fileId) => (
                                                        <div key={fileId} className='contact-input-container'>
                                                            <input
                                                                className='fileUpload'
                                                                id={`fileInput-${item._id}-${fileId}`}
                                                                type="file"
                                                                onChange={handleFileChange(item._id, fileId)}
                                                                multiple
                                                            />
                                                            <label className="custom-file-upload" htmlFor={`fileInput-${item._id}-${fileId}`}>
                                                                <span className="material-symbols-outlined">upload</span>
                                                                Choose File
                                                            </label>
                                                            <div className="file-list">
                                                                {files[item._id][fileId] && files[item._id][fileId].map((file, index) => (
                                                                    <div key={`${fileId}-${index}`} className='file-action-wrapper'>
                                                                        <span className={`file-name ${file ? 'visible' : ''}`}>{file.name}</span>
                                                                        {progress[item._id] && progress[item._id][fileId] && progress[item._id][fileId][index] !== undefined ? (
                                                                            <div className="upload-progress-container">
                                                                                <div className="upload-progress-bar">
                                                                                    <div className="upload-progress" style={{ width: `${progress[item._id][fileId][index]}%` }}></div>
                                                                                </div>
                                                                                <span className="progress-percentage">
                                                                                    {progress[item._id][fileId][index] === 100 ? 99 : progress[item._id][fileId][index]}%
                                                                                </span>
                                                                            </div>
                                                                        ) : (
                                                                            <button className="uploadBtn" onClick={() => handleUpload(item._id, fileId, index)} disabled={!file}>Submit Document</button>
                                                                        )}
                                                                        <button className="deleteBtn" onClick={() => handleDeleteFileInput(item._id, fileId, index)}>Delete</button>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    ))}
                                                    <button className="button" onClick={() => handleAddFileInput(item._id)}>
                                                        {getSubmittedFiles(item._id).length > 0 || (files[item._id] && Object.keys(files[item._id]).length > 0) ? "+ Add Additional File" : "+ Add File"}
                                                    </button>
                                                </>
                                            ) : item.type === 'text' ? (
                                                <div className="input-container">
                                                    {item.text_input_type === 'plain' ? (
                                                        <div className="quill-container">
                                                            <div className="quill-wrapper">
                                                                <ReactQuill
                                                                    value={text[item._id] || ''}
                                                                    onChange={(value) => handleTextChange(item._id)({ target: { value } })}
                                                                    modules={{ toolbar: `#toolbar-${item._id}` }}
                                                                    placeholder="Text Input"
                                                                />
                                                                {isEditing[item._id] && <button className="button" onClick={() => handleTextSubmit(item._id)}>Submit</button>}
                                                            </div>
                                                            <div
                                                                id={`toolbar-${item._id}`}
                                                                className={`ql-toolbar ql-snow toolbar ${isEditing[item._id] ? 'visible' : 'hidden'}`}
                                                            >
                                                                <span className="ql-formats">
                                                                    <button className="ql-bold"></button>
                                                                    <button className="ql-italic"></button>
                                                                </span>
                                                                <span className="ql-formats">
                                                                    <button className="ql-list" value="ordered"></button>
                                                                    <button className="ql-list" value="bullet"></button>
                                                                </span>
                                                                <span className="ql-formats">
                                                                    <button className="ql-link"></button>
                                                                    <button className="ql-image"></button>
                                                                </span>
                                                                <span className="ql-formats">
                                                                    <button className="ql-clean"></button>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className='textarea-wrapper'>
                                                            <textarea
                                                                value={text[item._id] || ''}
                                                                onChange={handleTextChange(item._id)}
                                                                placeholder={
                                                                    item.text_input_type === 'phone' ? 'Phone Input' :
                                                                        item.text_input_type === 'email' ? 'Email Input' :
                                                                            'Text Input'
                                                                }
                                                                type={
                                                                    item.text_input_type === 'phone' ? 'tel' :
                                                                        item.text_input_type === 'email' ? 'email' :
                                                                            'text'
                                                                }
                                                                rows={1}
                                                            />
                                                            {isEditing[item._id] && <button className="button" onClick={() => handleTextSubmit(item._id)}>Submit</button>}
                                                        </div>
                                                    )}
                                                </div>
                                            ) : item.type === 'business_hours' ? (
                                                <div className="business-hours-container">
                                                    <table className="business-hours-table">
                                                        <thead>
                                                            <tr>
                                                                <th>Day</th>
                                                                <th>Opening Time</th>
                                                                <th>Closing Time</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'].map(day => (
                                                                <tr key={day}>
                                                                    <td>{day.charAt(0).toUpperCase() + day.slice(1)}</td>
                                                                    <td>
                                                                        <select
                                                                            value={businessHours[item._id]?.[day]?.open || ''}
                                                                            onChange={(e) => handleBusinessHoursChange(item._id, day, 'open')(e)}
                                                                        >
                                                                            <option value="">Select Opening Time</option>
                                                                            <option value="Closed">Closed</option>
                                                                            <option value="24HR">24 Hours</option>
                                                                            {Array.from({ length: 24 }, (_, hour) => (
                                                                                <>
                                                                                    <option key={`${hour}-00`} value={`${hour % 12 || 12}:00 ${hour < 12 ? 'AM' : 'PM'}`}>
                                                                                        {`${hour % 12 || 12}:00 ${hour < 12 ? 'AM' : 'PM'}`}
                                                                                    </option>
                                                                                    <option key={`${hour}-30`} value={`${hour % 12 || 12}:30 ${hour < 12 ? 'AM' : 'PM'}`}>
                                                                                        {`${hour % 12 || 12}:30 ${hour < 12 ? 'AM' : 'PM'}`}
                                                                                    </option>
                                                                                </>
                                                                            ))}
                                                                        </select>
                                                                    </td>
                                                                    <td>
                                                                        <select
                                                                            value={businessHours[item._id]?.[day]?.close || ''}
                                                                            onChange={(e) => handleBusinessHoursChange(item._id, day, 'close')(e)}
                                                                            disabled={businessHours[item._id]?.[day]?.open === 'Closed' || businessHours[item._id]?.[day]?.open === '24HR'}
                                                                        >
                                                                            <option value="">Select Closing Time</option>
                                                                            {getEndTimes(businessHours[item._id]?.[day]?.open || '').map((time, idx) => (
                                                                                <option key={idx} value={time}>{time}</option>
                                                                            ))}
                                                                        </select>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                    {isEditing[item._id] && <button className="button" onClick={() => handleBusinessHoursSubmit(item._id)}>Submit</button>}
                                                </div>
                                            ) : item.type === 'multiple_choice' ? (
                                                <div className="multiple-choice-container">
                                                    {item.options.map(option => (
                                                        <div key={option} className="multiple-choice-option">
                                                            <div>
                                                                <input
                                                                    type="radio"
                                                                    id={`${item._id}-${option}`}
                                                                    name={item._id}
                                                                    value={option}
                                                                    checked={multipleChoice[item._id] === option}
                                                                    onChange={handleMultipleChoiceChange(item._id)}
                                                                    className={item.completed_info?.completed_at ? 'submitted' : ''}
                                                                />
                                                                <label htmlFor={`${item._id}-${option}`}>{option}</label>
                                                            </div>
                                                        </div>
                                                    ))}
                                                    {isEditing[item._id] && <button className="button" onClick={() => handleMultipleChoiceSubmit(item._id)}>Submit</button>}
                                                </div>
                                            ) : item.type === 'checkboxes' ? (
                                                <div className="checkbox-container">
                                                    {item.options.map(option => (
                                                        <div key={option} className="checkbox-option">
                                                            <input
                                                                type="checkbox"
                                                                id={`${item._id}-${option}`}
                                                                name={item._id}
                                                                value={option}
                                                                checked={checkboxes[item._id]?.includes(option) || false}
                                                                onChange={handleCheckboxChange(item._id, option)}
                                                            />
                                                            <label htmlFor={`${item._id}-${option}`}>{option}</label>
                                                        </div>
                                                    ))}
                                                    {isEditing[item._id] && <button className="button" onClick={() => handleCheckboxSubmit(item._id)}>Submit</button>}
                                                </div>
                                            ) : item.type === 'contact_info' ? (
                                                <>
                                                    {contactInfo[item._id]?.map((contact, index) => (
                                                        <div key={index} className="contact-input-container">
                                                            <input
                                                                type="text"
                                                                value={contact.contactName || ''}
                                                                onChange={handleContactInfoChange(item._id, index, 'contactName')}
                                                                placeholder="Contact Name"
                                                            />
                                                            <input
                                                                type="email"
                                                                value={contact.contactEmail || ''}
                                                                onChange={handleContactInfoChange(item._id, index, 'contactEmail')}
                                                                placeholder="Contact Email"
                                                            />
                                                            <input
                                                                type="tel"
                                                                value={contact.contactPhone || ''}
                                                                onChange={handleContactInfoChange(item._id, index, 'contactPhone')}
                                                                placeholder="Contact Phone"
                                                            />
                                                            {isEditing[item._id] && <button className="button" onClick={() => handleContactInfoSubmit(item._id, index)}>Submit</button>}
                                                            <button className="deleteBtn" onClick={() => handleDeleteContactInfo(item._id, index)}>Delete</button>
                                                        </div>
                                                    ))}
                                                    <button className="button" onClick={() => handleAddContactInfo(item._id)}>+ Add Another Contact</button>
                                                </>
                                            ) : item.type === 'multiple_text' ? (
                                                <>
                                                    {multipleText[item._id]?.map((textValue, index) => (
                                                        <div key={index} className="contact-input-container">
                                                            <textarea
                                                                value={textValue || ''}
                                                                onChange={handleMultipleTextChange(item._id, index)}
                                                                placeholder={
                                                                    item.text_input_type === 'phone' ? 'Phone Input' :
                                                                        item.text_input_type === 'email' ? 'Email Input' :
                                                                            'Text Input'
                                                                }
                                                                type={
                                                                    item.text_input_type === 'phone' ? 'tel' :
                                                                        item.text_input_type === 'email' ? 'email' :
                                                                            'text'
                                                                }
                                                                rows={1}
                                                            />
                                                            {isEditing[item._id] && <button className="button" onClick={() => handleMultipleTextSubmit(item._id)}>Submit</button>}
                                                            <button className="deleteBtn" onClick={() => handleDeleteMultipleText(item._id, index)}>Delete</button>
                                                        </div>
                                                    ))}
                                                    <button className="button" onClick={() => handleAddMultipleText(item._id)}>+ Add Another</button>
                                                </>
                                            ) : item.type === 'ad_schedule' ? (
                                                <div className="adScheduleContainer">
                                                    <table className="adScheduleTable">
                                                        <thead>
                                                            <tr>
                                                                <th>Day of Week</th>
                                                                <th>Run Ads?</th>
                                                                <th>Start Time</th>
                                                                <th>End Time</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map((day, index) => (
                                                                <tr key={index}>
                                                                    <td>{day}</td>
                                                                    <td>
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={!!adSchedule[item._id]?.[index]?.runAds}
                                                                            onChange={(e) => handleAdScheduleChange(item._id, index, 'runAds')(e)}
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        <select
                                                                            value={adSchedule[item._id]?.[index]?.startTime || ''}
                                                                            onChange={(e) => handleAdScheduleChange(item._id, index, 'startTime')(e)}
                                                                            disabled={!adSchedule[item._id]?.[index]?.runAds}
                                                                        >
                                                                            <option value="">Select Start Time</option>
                                                                            <option value="24 Hours">24 Hours</option>
                                                                            {Array.from({ length: 24 }, (_, hour) => (
                                                                                <>
                                                                                    <option key={`${hour}-00`} value={`${hour % 12 || 12}:00 ${hour < 12 ? 'AM' : 'PM'}`}>
                                                                                        {`${hour % 12 || 12}:00 ${hour < 12 ? 'AM' : 'PM'}`}
                                                                                    </option>
                                                                                    <option key={`${hour}-30`} value={`${hour % 12 || 12}:30 ${hour < 12 ? 'AM' : 'PM'}`}>
                                                                                        {`${hour % 12 || 12}:30 ${hour < 12 ? 'AM' : 'PM'}`}
                                                                                    </option>
                                                                                </>
                                                                            ))}
                                                                        </select>
                                                                    </td>
                                                                    <td>
                                                                        <select
                                                                            value={adSchedule[item._id]?.[index]?.endTime || ''}
                                                                            onChange={(e) => handleAdScheduleChange(item._id, index, 'endTime')(e)}
                                                                            disabled={!adSchedule[item._id]?.[index]?.runAds || adSchedule[item._id]?.[index]?.startTime === '24 Hours'}
                                                                        >
                                                                            <option value="">Select End Time</option>
                                                                            {getEndTimes(adSchedule[item._id]?.[index]?.startTime || '').map((time, idx) => (
                                                                                <option key={idx} value={time}>{time}</option>
                                                                            ))}
                                                                        </select>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                    {isEditing[item._id] && <button className="button" onClick={() => handleAdScheduleSubmit(item._id)}>Submit</button>}
                                                </div>
                                            ) : item.type === 'checklist_text' ? (
                                                <>
                                                    <div className='checklist-text-container'>
                                                        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                                                            <tbody>
                                                                {item.options.map((option, index) => (
                                                                    <tr key={index} style={{ borderBottom: '1px solid transparent' }}>
                                                                        <td style={{ padding: '8px', width: '40px' }}>
                                                                            <div className="checkbox-option">
                                                                                <input
                                                                                    type="checkbox"
                                                                                    id={`${item._id}-${index}`}
                                                                                    name={`${item._id}-${index}`}
                                                                                    checked={checklistText[item._id]?.[index]?.checked || false}
                                                                                    onChange={(e) => handleChecklistTextChange(item._id, index, 'checked', e.target.checked)}
                                                                                />
                                                                                <label htmlFor={`${item._id}-${index}`}></label>
                                                                            </div>
                                                                        </td>
                                                                        <td style={{ padding: '8px' }}>
                                                                            <label htmlFor={`${item._id}-${index}`} style={{ marginRight: '8px' }}>{option}</label>
                                                                        </td>
                                                                        <td style={{ padding: '8px', width: '100%' }}>
                                                                            <input
                                                                                type="text"
                                                                                value={checklistText[item._id]?.[index]?.value || ''}
                                                                                onChange={(e) => handleChecklistTextChange(item._id, index, 'value', e.target.value)}
                                                                                placeholder="Text Input"
                                                                                style={{
                                                                                    width: '100%',
                                                                                    backgroundColor: checklistText[item._id]?.[index]?.checked ? 'white' : '#e0e0e0',
                                                                                    cursor: checklistText[item._id]?.[index]?.checked ? 'text' : 'not-allowed'
                                                                                }}
                                                                                disabled={!checklistText[item._id]?.[index]?.checked}
                                                                            />
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                        <div className='checklist-text-container'>
                                                            {isEditing[item._id] && <button className="submitButton" onClick={() => handleChecklistTextSubmit(item._id)}>Submit</button>}
                                                        </div>
                                                    </div>
                                                </>
                                            ) : null}
                                            {item.completed_info?.completed_at && <span className='completed-item-text'>Completed on: {formatDate(item.completed_info.completed_at)} by {item.completed_info.completed_by}</span>}
                                            {item.edited_info?.map((edit, index) => (
                                                <span key={index} className='edited-item-text'>Edited on: {formatDate(edit.edited_at)} by {edit.edited_by}</span>
                                            ))}
                                            {editMode && (
                                                <button className="delete-item-button" onClick={(e) => {
                                                    e.stopPropagation(); // Prevent the item toggle when clicking the delete button
                                                    handleDeleteChecklistItem(item._id);
                                                }}>
                                                    <span className="delete-question">Delete Item</span>
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ];

                            // Insert conditional items right after their parent
                            const conditionalItemsToRender = renderConditionalItems(item._id);
                            if (conditionalItemsToRender.length > 0) {
                                items.push(...conditionalItemsToRender);
                            }

                            return items;
                        })}

                        {editMode && (
                            <div className="add-checklist-item-container">
                                <button
                                    className="add-checklist-item-button"
                                    onClick={() => handleAddNewChecklistItem(openSection)}
                                >
                                    + Add New Checklist Item
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            )}

            {/* <h3>Uploaded Documents</h3>
            <ul>
                {clientData.documents?.map((doc) => (
                    <li key={doc._id}>
                        <a href={doc.file_url} target="_blank" rel="noopener noreferrer" download>{doc.file_name || 'Download Document'}</a>
                    </li>
                ))}
            </ul> */}
            {message && <p>{message}</p>}

            {showModal && (
                <div className="modal">
                    <div className="modal-content">
                        <span className="close" onClick={() => setShowModal(false)}>&times;</span>
                        <p>{modalMessage}</p>
                    </div>
                </div>
            )}

            {showNewSectionModal && (
                <div className="modal">
                    <div className="modal-content">
                        <span className="close" onClick={() => setShowNewSectionModal(false)}>&times;</span>
                        <h2>Add New Section</h2>
                        <div className="new-section-form">
                            <label className="new-section-label">Add Default Section</label>
                            <CustomDropdown
                                options={filteredDefaultSections.map(section => section.name)}
                                selectedOption={selectedDefaultSection}
                                onChange={setSelectedDefaultSection}
                            />
                            <div className="or-separator">
                                <span>or</span>
                            </div>
                            <label className="new-section-label">Create New Section</label>
                            <input
                                type="text"
                                className="new-section-input"
                                value={newSectionName}
                                onChange={(e) => setNewSectionName(e.target.value)}
                                placeholder="Section Name"
                            />
                            <input
                                type="date"
                                className="new-section-input"
                                value={newSectionDueDate}
                                onChange={(e) => setNewSectionDueDate(e.target.value)}
                                placeholder="Due Date"
                            />
                        </div>
                        <div className="modal-buttons">
                            <button className="cancel-button" onClick={() => setShowNewSectionModal(false)}>Cancel</button>
                            <button className="submit-button" onClick={handleAddNewSection}>Submit</button>
                        </div>
                    </div>
                </div>
            )}

            {showAddItemModal && (
                <div className="modal">
                    <div className="modal-content">
                        <span className="close" onClick={() => setShowAddItemModal(false)}>&times;</span>
                        <h2 className="new-item-header">Add New Item</h2>
                        <div className="new-item-form">
                            <label className="new-item-label">Name</label>
                            <input
                                type="text"
                                className="new-item-input"
                                value={newItemName}
                                onChange={(e) => setNewItemName(e.target.value)}
                                placeholder="Item Name"
                            />
                            <label className="new-item-label">Type</label>
                            <select
                                className="new-item-select"
                                value={newItemType}
                                onChange={(e) => setNewItemType(e.target.value)}
                            >
                                <option value="">Select Type</option>
                                <option value="text">Text</option>
                                <option value="multiple_choice">Multiple Choice</option>
                                <option value="checkboxes">Checkboxes</option>
                                <option value="business_hours">Business Hours</option>
                                <option value="contact_info">Contact Info</option>
                                <option value="multiple_text">Multiple Text</option>
                            </select>
                            {(newItemType === 'multiple_choice' || newItemType === 'checkboxes') && (
                                <>
                                    <label className="new-item-label">Options (one per line)</label>
                                    <textarea
                                        className="new-item-textarea"
                                        value={newItemOptions}
                                        onChange={(e) => setNewItemOptions(e.target.value)}
                                        placeholder="Enter options, one per line"
                                        rows={4}
                                    />
                                </>
                            )}
                            {(newItemType === 'text' || newItemType === 'multiple_text') && (
                                <>
                                    <label className="new-item-label">Text Type</label>
                                    <select
                                        className="new-item-select"
                                        value={newItemTextType}
                                        onChange={(e) => setNewItemTextType(e.target.value)}
                                    >
                                        <option value="">Select Text Type</option>
                                        <option value="text">Text</option>
                                        <option value="email">Email</option>
                                        <option value="phone">Phone</option>
                                    </select>
                                </>
                            )}
                            <div className="checkbox-option">
                                <input
                                    type="checkbox"
                                    checked={newItemOptional}
                                    onChange={(e) => setNewItemOptional(e.target.checked)}
                                />
                                <label className="new-item-label">Optional</label>
                            </div>
                            <div className="checkbox-option">
                                <input
                                    type="checkbox"
                                    checked={newItemLocked}
                                    onChange={(e) => setNewItemLocked(e.target.checked)}
                                />
                                <label className="new-item-label">Locked</label>
                            </div>
                        </div>
                        <div className="modal-buttons">
                            <button className="cancel-button" onClick={() => setShowAddItemModal(false)}>Cancel</button>
                            <button className="submit-button" onClick={handleNewItemSubmit}>Submit</button>
                        </div>
                    </div>
                </div>
            )}


            <Footer clientName={clientData.client?.name} />
            <ToastContainer className="custom-toast-container" />
        </div>
    );

};

export default AdminClientView;





