import React from 'react';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render shows the fallback UI
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log error data to an error-reporting service here
    this.setState({ error, errorInfo });
  }

  render() {
    if (this.state.hasError) {
      // Render any custom fallback UI you like
      return (
        <div style={{ padding: '2rem' }}>
          <h1>Oops! Something went wrong.</h1>
          <p>
            An unexpected error occurred while loading the Admin Client View.  
            Try refreshing or contact support if it persists.
          </p>
          {process.env.NODE_ENV === 'development' && this.state.errorInfo && (
            <details style={{ whiteSpace: 'pre-wrap', marginTop: '1rem' }}>
              <summary>Dev stack trace</summary>
              {this.state.error && this.state.error.toString()}
              <br />
              {this.state.errorInfo.componentStack}
            </details>
          )}
        </div>
      );
    }

    // If no error, just render children normally
    return this.props.children; 
  }
}

export default ErrorBoundary;
