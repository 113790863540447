import React, { useReducer, useEffect, useState } from "react";
import styles from "./DefaultPeople.module.css";
import { getTeamMembers, getManagersAndStrategists } from "../api";
import { FaEllipsisV, FaPlus } from "react-icons/fa";
import EditTeamMember from "./UIElements/EditTeamMember";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './ToastifyCustom.css';
import hintImage from '../assets/icons/lightbulb_icon.png';

// Reducer function for managing team members state
const teamReducer = (state, action) => {
    switch (action.type) {
        case "SET_MEMBERS":
            return action.payload; // Replaces entire list
        case "ADD_MEMBER":
            return [...state, action.payload]; // Adds new member
        case "UPDATE_MEMBER":
            return state.map((member) =>
                member._id === action.payload._id ? action.payload : member
            );
        case "DELETE_MEMBER":
            return state.filter((member) => member._id !== action.payload);
        default:
            return state;
    }
};

const DefaultPeople = () => {
    const [teamMembers, dispatch] = useReducer(teamReducer, []);
    const [editingMember, setEditingMember] = useState(null);
    const [searchQuery, setSearchQuery] = useState("");
    const [addingMember, setAddingMember] = useState(false);
    const [managersAndStrategists, setManagersAndStrategists] = useState({ managers: [], strategists: [] });
    const [nameFilter, setNameFilter] = useState("");
    const [emailFilter, setEmailFilter] = useState("");
    const [titleFilter, setTitleFilter] = useState("");
    const [tagFilter, setTagFilter] = useState("");

    useEffect(() => {
        const fetchData = async () => {
            try {
                const members = await getTeamMembers();
                dispatch({ type: "SET_MEMBERS", payload: members.data });

                const managersStrategists = await getManagersAndStrategists();
                setManagersAndStrategists(managersStrategists);
            } catch (error) {
                console.error("Failed to fetch data:", error);
            }
        };

        fetchData();
    }, []);

    return (
        <div>
            <div className={styles.clientInfoHint}>
                                <div className={styles.hintIconContainer}>
                                    <img src={hintImage} className={styles.hintIcon} alt="Hint" />
                                </div>
                                <h2 className={styles.hintText}>The team members entered here will be used for Account Manager/Project Manager selection options, and the team introduction email</h2>
                            </div>
            <div className={styles.numPeopleHeader}>
                <div className={styles.numPeopleTextContainer}>
                    <p className={styles.numPeopleTextNumber}>{teamMembers.length || "0"}</p>
                </div>
                <p className={styles.numPeopleLabel}>Ferocious Team Members</p>

                <div className={styles.searchAndAddContainer}>
                    <button className={styles.addMemberBtn} onClick={() => setAddingMember(true)}>
                        <FaPlus />
                    </button>
                    <input
                        type="text"
                        placeholder="Search by Name, Email, Title, or Tags..."
                        className={styles.searchInput}
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value.toLowerCase())}
                    />

                </div>
            </div>

            <table className={styles.teamMemberTable}>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Title</th>
                        <th>
                            Tags
                            <select
                                className={styles.filterDropdown}
                                value={tagFilter}
                                onChange={(e) => setTagFilter(e.target.value)}
                            >
                                <option value="">All</option>
                                <option value="Project Manager">Project Manager</option>
                                <option value="Account Manager">Account Manager</option>
                            </select>
                        </th>

                        <th>Edit</th>
                    </tr>
                </thead>
                <tbody>
                    {teamMembers
                        .filter(member => {
                            const fullName = `${member.firstName} ${member.lastName}`.toLowerCase();
                            const email = member.email.toLowerCase();
                            const title = (member.title || "").toLowerCase();
                            const tags = [];
                            if (managersAndStrategists?.managers?.some(m => m.email === member.email)) {
                                tags.push("Project Manager");
                            }
                            if (managersAndStrategists?.strategists?.some(s => s.email === member.email)) {
                                tags.push("Account Manager");
                            }

                            // Apply both search query and tag filter
                            return (
                                (fullName.includes(searchQuery) ||
                                    email.includes(searchQuery) ||
                                    title.includes(searchQuery) ||
                                    tags.some(tag => tag.toLowerCase().includes(searchQuery))) &&
                                (tagFilter === "" || tags.includes(tagFilter)) // ✅ Tag filter logic
                            );


                            return (
                                fullName.includes(searchQuery) ||
                                email.includes(searchQuery) ||
                                title.includes(searchQuery) ||
                                tags.includes(searchQuery)
                            );
                        })
                        .map(member => (
                            <tr key={member._id || member.email}>
                                <td className={styles.boldCol}>
                                    <img
                                        src={member.profilePicURL}
                                        alt={`${member.firstName} ${member.lastName}`}
                                        className={styles.memberImage}
                                    />
                                    {member.firstName} {member.lastName}
                                </td>
                                <td>{member.email}</td>
                                <td>{member.title || "No title"}</td>
                                <td>
                                    <div className={styles.tagsContainer}>
                                        {managersAndStrategists?.managers?.some(m => m.email === member.email) && <span className={styles.tag}>Project Manager</span>}
                                        {managersAndStrategists?.strategists?.some(s => s.email === member.email) && <span className={styles.tag}>Account Manager</span>}
                                    </div>
                                </td>
                                <td>
                                    <button className={styles.editBtn} onClick={() => setEditingMember(member)}>
                                        <FaEllipsisV />
                                    </button>
                                </td>
                            </tr>
                        ))}
                </tbody>

            </table>

            {(editingMember || addingMember) && (
                <EditTeamMember
                    member={editingMember || { firstName: "", lastName: "", title: "", email: "", profilePicURL: "" }}
                    managersAndStrategists={managersAndStrategists}
                    onSave={async () => {
                        try {
                            // ✅ Fetch updated list of team members from the backend
                            const updatedMembersList = await getTeamMembers();
                            dispatch({ type: "SET_MEMBERS", payload: updatedMembersList.data });

                            // ✅ Fetch updated managers and strategists
                            const updatedRoles = await getManagersAndStrategists();
                            setManagersAndStrategists(updatedRoles);
                        } catch (error) {
                            console.error("❌ Error fetching updated team members:", error);
                            toast.error("Failed to refresh team members.");
                        }

                        setEditingMember(null);
                    }}

                    onClose={() => {
                        setEditingMember(null);
                        setAddingMember(false);
                    }}
                />
            )}
        </div>
    );
};

export default DefaultPeople;
