// SearchBar.js

import React from 'react';
import styles from './SearchBar.module.css'; // or reuse ExistingClients.module.css if you prefer

const SearchBar = ({ searchTerm, setSearchTerm, hintText }) => {
    return (
        <div className={styles.searchContainer}>
            <input
                type="text"
                placeholder={hintText}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className={styles.searchInput}
            />
            <i className={`${styles.searchIcon} material-icons`}>search</i>
        </div>
    );
};

export default SearchBar;
