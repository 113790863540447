import React, { useState, useEffect } from 'react';
import styles from './SuperAdminDashboard.module.css';
import NavBar from './NavBar';
import TabBar from './TabBar';
import axios from 'axios';
import CustomDropdown from './CustomDropdown';
import CustomDropdownSecondary from './CustomDropdownSecondary';
import DefaultItemsHeader from "./UIElements/SuperAdminHeader";
import DefaultPeople from './DefaultPeople';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './ToastifyCustom.css';
import Integrations from './Integrations'; // Import the new Integrations component
import deleteIcon from '../assets/icons/delete_24dp.svg';
import { ReactComponent as ExpandIcon } from '../assets/icons/expand_24dp.svg';
import { ReactComponent as ConditionalIcon } from '../assets/icons/alt_route.svg';

const SuperAdminDashboard = () => {
    const userName = "User First Name"; // Replace with actual user name logic
    const [strategists, setStrategists] = useState([]);
    const [managers, setManagers] = useState([]);
    const [sections, setSections] = useState([]);
    const [collapsedSections, setCollapsedSections] = useState([]);
    const [isEditMode, setIsEditMode] = useState(false);
    const [currentTab, setCurrentTab] = useState(0);
    const [teamMembers, setTeamMembers] = useState([]);
    const [managerEmails, setManagerEmails] = useState([]);
    const [userEmail, setUserEmail] = useState('');

    const itemTypes = ['file', 'text', 'business_hours', 'multiple_choice', 'checkboxes', 'contact_info', 'multiple_text', 'ad_schedule', 'checklist_text', 'connect_iframe'];
    const textInputTypes = ['plain', 'phone', 'email'];

    useEffect(() => {
        const fetchOptions = async () => {
            const response = await axios.get('https://onboarding-crm-06619bb63b87.herokuapp.com/admin/options');
            // const response = await axios.get('http://localhost:3000/admin/options');
            if (response.data) {
                const validStrategists = (response.data.strategists || []).map(s => {
                    if (typeof s === 'string') {
                        return { name: s, email: '' };
                    } else {
                        return { name: s.name || '', email: s.email || '' };
                    }
                }).sort((a, b) => a.name.localeCompare(b.name));

                const validManagers = (response.data.managers || []).map(m => {
                    if (typeof m === 'string') {
                        return { name: m, email: '' };
                    } else {
                        return { name: m.name || '', email: m.email || '' };
                    }
                }).sort((a, b) => a.name.localeCompare(b.name));

                const sortedTeamMembers = (response.data.teamMembers || []).sort((a, b) =>
                    `${a.firstName} ${a.lastName}`.localeCompare(`${b.firstName} ${b.lastName}`)
                );

                setStrategists(validStrategists);
                setManagers(validManagers);
                setTeamMembers(sortedTeamMembers);
            }

            const sectionsResponse = await axios.get('https://onboarding-crm-06619bb63b87.herokuapp.com/admin/default-checklist-items');
            // const sectionsResponse = await axios.get('http://localhost:3000/admin/default-checklist-items');
            if (sectionsResponse.data) {
                const sectionsWithConditionalItems = sectionsResponse.data.map(section => ({
                    ...section,
                    items: section.items.map(item => ({
                        ...item,
                        options: Array.isArray(item.options) ? item.options : item.options.split('\n').map(option => option.trim()),
                        text_input_type: item.text_input_type || 'plain', // Ensure text_input_type is initialized
                        conditionalItems: item.conditionalItems?.map(conditionalItem => ({
                            name: conditionalItem.item_name || '',
                            description: conditionalItem.item_description || '',
                            type: conditionalItem.item_type || 'text',
                            showOn: conditionalItem.show_on || '',
                            showOnOptions: item.options || [],
                            text_input_type: conditionalItem.text_input_type || 'plain',
                            subfolder: conditionalItem.subfolder,
                            options: Array.isArray(conditionalItem.options) ? conditionalItem.options : []
                        })) || []
                    }))
                }));

                // Sort sections alphabetically
                const sortedSections = sectionsWithConditionalItems.sort((a, b) => a.name.localeCompare(b.name));
                console.log(JSON.stringify(sortedSections, null, 2));

                // Set sections and initialize collapsed state for all sections
                setSections(sortedSections);
                setCollapsedSections(sortedSections.map((_, index) => index));
            }
        };
        fetchOptions();

    }, []);

    useEffect(() => {
        const storedUser = JSON.parse(localStorage.getItem('user'));
        if (storedUser && storedUser.email) {
            setUserEmail(storedUser.email);
        }
    }, []);


    const itemTypeMapping = {
        file: 'File Upload',
        text: 'Text Input',
        business_hours: 'Business Hours',
        multiple_choice: 'Multiple Choice',
        checkboxes: 'Checkboxes',
        contact_info: 'Contact Information',
        multiple_text: 'Multiple Text Inputs',
        ad_schedule: 'Ad Schedule',
        checklist_text: 'Checklist Text',
        connect_iframe: 'Embedded iFrame'
    };

    const readableItemTypes = Object.keys(itemTypeMapping);

    const getReadableItemType = (value) => itemTypeMapping[value] || value;

    const textInputTypeMapping = {
        plain: 'Plain Text',
        phone: 'Phone Number',
        email: 'Email Address'
    };

    const getReadableTextInputType = (value) => textInputTypeMapping[value] || value;



    const toggleEditMode = () => {
        setIsEditMode(!isEditMode);
    };

    const handleAddStrategist = () => {
        setStrategists([...strategists, { name: `Person #${strategists.length + 1}`, email: '' }].sort((a, b) => a.name.localeCompare(b.name)));
    };

    const handleAddManager = () => {
        setManagers([...managers, { name: `Person #${managers.length + 1}`, email: '' }].sort((a, b) => a.name.localeCompare(b.name)));
    };

    const handleAddTeamMember = () => {
        setTeamMembers([...teamMembers, { firstName: '', lastName: '', title: '', profilePicURL: '', email: '' }]);
    };

    const handleTeamMemberChange = (index, field, value) => {
        const newTeamMembers = [...teamMembers];
        newTeamMembers[index][field] = value;
        setTeamMembers(newTeamMembers);
    };

    const handleFileChange = async (index, file) => {
        const newTeamMembers = [...teamMembers];
        const formData = new FormData();
        formData.append('file', file);

        // Check if the team member has an ID (exists in the database)
        const teamMemberId = newTeamMembers[index]._id;

        if (teamMemberId) {
            formData.append('team_member_id', teamMemberId);
        }

        try {
            const response = await axios.post('https://onboarding-crm-06619bb63b87.herokuapp.com/admin/upload-profile-pic', formData, {
                // const response = await axios.post('localhost:3000/admin/upload-profile-pic', formData, {

                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            newTeamMembers[index].profilePicURL = response.data.profilePicURL;
            setTeamMembers(newTeamMembers);
            toast.success('Profile picture uploaded successfully');
        } catch (error) {
            console.error('Error uploading profile picture:', error);
            toast.error('Failed to upload profile picture');
        }
    };



    const handleDeleteTeamMember = (index) => {
        const newTeamMembers = teamMembers.filter((_, i) => i !== index);
        setTeamMembers(newTeamMembers);
    };


    const handleAddSection = async () => {
        const newSections = [...sections, { name: '', daysFromToday: '', items: [] }];
        const sortedSections = newSections.sort((a, b) => a.name.localeCompare(b.name));
        setSections(sortedSections);
        setCollapsedSections(sortedSections.map((_, index) => index));
        window.scrollTo({ top: 0, behavior: 'smooth' });
    
        try {
            await axios.post('https://onboarding-crm-06619bb63b87.herokuapp.com/activity-log', {
                user: userEmail, // Replace with actual logged-in user
                action: 'Added a new section'
            });
        } catch (error) {
            console.error('Error logging activity:', error);
        }
    };
    

    const handleAddItem = (sectionIndex) => {
        const newSections = [...sections];
        newSections[sectionIndex].items.push({ name: '', type: '', description: '', options: [], locked: false, conditionalItems: [], optional: false });
        setSections(newSections);
    };

    const handleAddConditionalItem = (sectionIndex, itemIndex) => {
        const newSections = [...sections];
        const item = newSections[sectionIndex].items[itemIndex];
        
        if (item.type === 'multiple_choice' || item.type === 'checkboxes') {
            const showOnOptions = item.options ? item.options : [];
            newSections[sectionIndex].items[itemIndex].conditionalItems.push({
                name: '',
                description: '',
                type: 'text', // Default type for new conditional items
                show_on: [], // Allow multiple selection
                options: [], // Supports options if multiple choice
                showOnOptions,
            });
            setSections(newSections);
        }
    };
    
    

    const handleDeleteStrategist = (index) => {
        const newStrategists = strategists.filter((_, i) => i !== index);
        setStrategists(newStrategists.sort());
    };

    const handleDeleteManager = (index) => {
        let newManagers = [...managers];
        newManagers = managers.filter((_, i) => i !== index);
        setManagers(newManagers.sort());
    };

    const handleDeleteSection = async (index) => {
        const deletedSection = sections[index]; // Capture the deleted section details
        const newSections = sections.filter((_, i) => i !== index);
        setSections(newSections);
    
        try {
            await axios.post('https://onboarding-crm-06619bb63b87.herokuapp.com/activity-log', {
                user: userEmail, 
                action: `Deleted section: ${deletedSection.name}`
            });
        } catch (error) {
            console.error('Error logging activity:', error);
        }
    };
    

    const handleDeleteItem = (sectionIndex, itemIndex) => {
        const newSections = [...sections];
        newSections[sectionIndex].items = newSections[sectionIndex].items.filter((_, i) => i !== itemIndex);
        setSections(newSections);
    };

    const handleDeleteConditionalItem = (sectionIndex, itemIndex, conditionalIndex) => {
        const newSections = [...sections];
        newSections[sectionIndex].items[itemIndex].conditionalItems = newSections[sectionIndex].items[itemIndex].conditionalItems.filter((_, i) => i !== conditionalIndex);
        setSections(newSections);
    };

    const handleToggleLock = (sectionIndex, itemIndex) => {
        const newSections = [...sections];
        newSections[sectionIndex].items[itemIndex].locked = !newSections[sectionIndex].items[itemIndex].locked;
        setSections(newSections);
    };

    const handleToggleOptional = (sectionIndex, itemIndex) => {
        const newSections = [...sections];
        newSections[sectionIndex].items[itemIndex].optional = !newSections[sectionIndex].items[itemIndex].optional;
        setSections(newSections);
    };

    const handleStrategistChange = (index, field, value) => {
        const newStrategists = [...strategists];
        newStrategists[index] = { ...newStrategists[index], [field]: value };
        setStrategists(newStrategists);
    };


    const handleManagerChange = (index, field, value) => {
        const newManagers = [...managers];
        newManagers[index] = { ...newManagers[index], [field]: value };
        setManagers(newManagers);
    };

    const handleSectionChange = (index, field, value) => {
        const newSections = [...sections];
        newSections[index][field] = value;
        setSections(newSections);
    };

    const handleItemChange = (sectionIndex, itemIndex, field, value) => {
        const newSections = [...sections];
        newSections[sectionIndex].items[itemIndex][field] = value;

        if (field === 'options' && (newSections[sectionIndex].items[itemIndex].type === 'multiple_choice' || newSections[sectionIndex].items[itemIndex].type === 'checkboxes')) {
            const optionsArray = Array.isArray(value)
                ? value  // Already an array, no need to split
                : value.toString().split('\n').map(option => option.trim()); // Convert to string and split

            newSections[sectionIndex].items[itemIndex].options = optionsArray;
            newSections[sectionIndex].items[itemIndex].conditionalItems.forEach(conditionalItem => {
                conditionalItem.showOnOptions = optionsArray;
            });
        }

        setSections(newSections);
    };



    const handleConditionalItemChange = (sectionIndex, itemIndex, conditionalItemIndex, field, value) => {
        const newSections = [...sections];
        newSections[sectionIndex].items[itemIndex].conditionalItems[conditionalItemIndex][field] = value;

        if (field === 'options' && (newSections[sectionIndex].items[itemIndex].conditionalItems[conditionalItemIndex].type === 'multiple_choice' ||
            newSections[sectionIndex].items[itemIndex].conditionalItems[conditionalItemIndex].type === 'checkboxes')) {
            const optionsArray = Array.isArray(value)
                ? value
                : value.toString().split('\n').map(option => option.trim());

            newSections[sectionIndex].items[itemIndex].conditionalItems[conditionalItemIndex].options = optionsArray;
        }

        if ((field === 'type' && (value === 'text' || value === 'multiple_text')) &&
            !newSections[sectionIndex].items[itemIndex].conditionalItems[conditionalItemIndex].text_input_type) {
            newSections[sectionIndex].items[itemIndex].conditionalItems[conditionalItemIndex].text_input_type = 'plain';
        }

        if (field === 'type' && value !== 'file') {
            delete newSections[sectionIndex].items[itemIndex].conditionalItems[conditionalItemIndex].subfolder;
        }

        setSections(newSections);
    };



    const handleSaveSections = async () => {
        try {
            const validStrategists = strategists.filter(s => s && s.name).sort((a, b) => a.name.localeCompare(b.name));
            const validManagers = managers.filter(m => m && m.name).sort((a, b) => a.name.localeCompare(b.name));

            const updatedSections = sections.map(section => ({
                ...section,
                items: section.items.map(item => ({
                    ...item,
                    conditionalItems: item.conditionalItems.map(conditionalItem => ({
                        ...conditionalItem,
                        text_input_type: conditionalItem.text_input_type || 'plain', // Ensure text_input_type is included
                        subfolder: conditionalItem.subfolder || '' // Ensure subfolder is included in the saved data

                    }))
                }))
            }));

            // Sort sections alphabetically
            const sortedSections = updatedSections.sort((a, b) => a.name.localeCompare(b.name));

            await axios.put('https://onboarding-crm-06619bb63b87.herokuapp.com/admin/default-checklist-items', sortedSections);
            // await axios.put('http://localhost:3000/admin/default-checklist-items', sortedSections);

            await axios.put('https://onboarding-crm-06619bb63b87.herokuapp.com/admin/options', {
                // await axios.put('http://localhost:3000/admin/options', {

                strategists: validStrategists,
                managers: validManagers,
                teamMembers
            });

            toast.success('Sections and options saved successfully');
            setIsEditMode(false);
        } catch (error) {
            console.error('Error saving sections and options:', error.response?.data || error.message);
            toast.error(`Failed to save sections and options: ${error.response?.data?.message || error.message}`);
        }
    };






    const moveItemUp = (sectionIndex, itemIndex) => {
        if (itemIndex === 0) return; // Already at the top
        const newSections = [...sections];
        const items = newSections[sectionIndex].items;
        const [movedItem] = items.splice(itemIndex, 1);
        items.splice(itemIndex - 1, 0, movedItem);
        setSections(newSections);

        setTimeout(() => {
            document.getElementById(`item-${sectionIndex}-${itemIndex - 1}`).scrollIntoView({ behavior: 'smooth', block: 'center' });
        }, 0);
    };

    const moveItemDown = (sectionIndex, itemIndex) => {
        const items = sections[sectionIndex].items;
        if (itemIndex === items.length - 1) return; // Already at the bottom
        const newSections = [...sections];
        const [movedItem] = items.splice(itemIndex, 1);
        items.splice(itemIndex + 1, 0, movedItem);
        setSections(newSections);

        setTimeout(() => {
            document.getElementById(`item-${sectionIndex}-${itemIndex + 1}`).scrollIntoView({ behavior: 'smooth', block: 'center' });
        }, 0);
    };

    const moveSectionUp = (index) => {
        if (index === 0) return; // Already at the top
        const newSections = [...sections];
        const [movedSection] = newSections.splice(index, 1);
        newSections.splice(index - 1, 0, movedSection);
        setSections(newSections);
    };

    const moveSectionDown = (index) => {
        if (index === sections.length - 1) return; // Already at the bottom
        const newSections = [...sections];
        const [movedSection] = newSections.splice(index, 1);
        newSections.splice(index + 1, 0, movedSection);
        setSections(newSections);
    };

    const toggleCollapseSection = (index) => {
        const newCollapsedSections = [...collapsedSections];
        if (newCollapsedSections.includes(index)) {
            newCollapsedSections.splice(newCollapsedSections.indexOf(index), 1);
        } else {
            newCollapsedSections.push(index);
        }
        setCollapsedSections(newCollapsedSections);
    };


    const downloadSectionsAsJson = () => {
        const filteredSections = sections.map(section => ({
            name: section.name,
            daysFromToday: section.daysFromToday,
            items: section.items.map(item => ({
                name: item.name,
                type: item.type,
                description: item.description,
                options: item.options,
                text_input_type: item.text_input_type, // Include text_input_type for items
                conditionalItems: item.conditionalItems.map(conditionalItem => ({
                    name: conditionalItem.name,
                    description: conditionalItem.description,
                    type: conditionalItem.type,
                    showOn: conditionalItem.showOn,
                    showOnOptions: conditionalItem.showOnOptions,
                    text_input_type: conditionalItem.text_input_type,
                    subfolder: conditionalItem.subfolder
                })),
                locked: item.locked,
                optional: item.optional,
                subfolder: item.subfolder
            }))
        }));

        const dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(filteredSections, null, 2));
        const downloadAnchorNode = document.createElement('a');
        downloadAnchorNode.setAttribute("href", dataStr);
        downloadAnchorNode.setAttribute("download", "sections.json");
        document.body.appendChild(downloadAnchorNode); // required for firefox
        downloadAnchorNode.click();
        downloadAnchorNode.remove();
    };

    const handleUploadSections = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                try {
                    const uploadedSections = JSON.parse(e.target.result);
                    setSections(uploadedSections);
                } catch (error) {
                    toast.error('Failed to upload sections. Invalid JSON file.');
                }
            };
            reader.readAsText(file);
        }
    };


    const renderOptions = (item) => {
        const options = [];
        if (item.locked) options.push(<li key="locked">Locked</li>);
        if (item.type === 'multiple_choice' || item.type === 'checkboxes') {
            item.options.forEach((option, index) => {
                options.push(<li key={index}>{option}</li>);
            });
        }
        return <ul>{options}</ul>;
    };


    const renderContent = () => {
        switch (currentTab) {
            case 1: // Default Items
                return (
                    
                    <DefaultPeople />

                );
            case 0: // About
                return (
                    <div className={styles.aboutContainer}>

                            {/* <DefaultItemsHeader /> */}
                        <div className={styles.fullWidthTile}>
                            <div className={styles.tileTop}>
                                <h3>
                                    Default Checklist Items
                                    <button onClick={isEditMode ? handleSaveSections : toggleEditMode} className={styles.editButton}>
                                        {isEditMode ? 'Save' : 'Edit'}
                                    </button>
                                    <button onClick={downloadSectionsAsJson} className={styles.downloadButton}>
                                        Download Sections as JSON
                                    </button>
                                    <label htmlFor="uploadSections" className={styles.uploadLabel}>
                                        Upload Sections from JSON
                                        <input type="file" id="uploadSections" className={styles.uploadInput} onChange={handleUploadSections} />
                                    </label>
                                </h3>

                                {sections.map((section, sectionIndex) => (
                                    <div key={sectionIndex} className={styles.section}>
                                        <div className={styles.sectionHeader}>
                                            {isEditMode ? (
                                                <>

                                                    <div className={styles.sectionNameEditContainer}>

                                                        <input
                                                            type="text"
                                                            placeholder="Section Name"
                                                            value={section.name}
                                                            onChange={(e) => handleSectionChange(sectionIndex, 'name', e.target.value)}
                                                            className={styles.sectionNameEdit}
                                                        />
                                                        <div className={styles.dueDateContainer}>
                                                            <span><b>Due:</b> Today + </span>
                                                            <input
                                                                type="number"
                                                                placeholder="days"
                                                                value={section.daysFromToday}
                                                                onChange={(e) => handleSectionChange(sectionIndex, 'daysFromToday', e.target.value)}
                                                                className={styles.dueDateDays}
                                                            />
                                                            <span> days</span>
                                                        </div>
                                                    </div>
                                                    <div className={styles.sectionActionButtons}>
                                                        <button
                                                            onClick={() => handleDeleteSection(sectionIndex)}
                                                            className={styles.deleteButton}
                                                            title='Delete Section'
                                                        >
                                                            <img src={deleteIcon} alt="Delete" className={styles.icon} />
                                                        </button>
                                                        <button
                                                            onClick={() => moveSectionUp(sectionIndex)}
                                                            className={styles.moveButton}
                                                            disabled={sectionIndex === 0}
                                                            title='Move Section Up'
                                                        >
                                                            <span className="material-icons">keyboard_arrow_up</span>
                                                        </button>
                                                        <button
                                                            onClick={() => moveSectionDown(sectionIndex)}
                                                            className={styles.moveButton}
                                                            disabled={sectionIndex === sections.length - 1}
                                                            title='Move Section Down'
                                                        >
                                                            <span className="material-icons">keyboard_arrow_down</span>
                                                        </button>

                                                        <button
                                                            onClick={() => toggleCollapseSection(sectionIndex)}
                                                            className={`${styles.collapseButton} ${collapsedSections.includes(sectionIndex) ? '' : styles.expanded}`}
                                                            title='Collapse/Expand Section'
                                                        >
                                                            <ExpandIcon className={styles.icon}
                                                                style={{ transform: collapsedSections.includes(sectionIndex) ? 'rotate(180deg)' : 'rotate(0deg)' }}
                                                            />
                                                        </button>
                                                    </div>


                                                </>
                                            ) : (
                                                <>
                                                    <div>
                                                        <p className={styles.sectionName}>{section.name}</p>
                                                        <p className={styles.dueDate}>Due: Today + {section.daysFromToday} days</p>
                                                        <button onClick={() => toggleCollapseSection(sectionIndex)} className={styles.collapseButton}>
                                                            {collapsedSections.includes(sectionIndex) ? 'Expand' : 'Collapse'}
                                                        </button>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                        {!collapsedSections.includes(sectionIndex) && (
                                            <>

                                                <div className={styles.defaultChecklistItemsContainer}>
                                                    {section.items.map((item, itemIndex) => (
                                                        <div key={itemIndex} id={`item-${sectionIndex}-${itemIndex}`} className={styles.itemContainer}>
                                                            <div>
                                                                {isEditMode ? (

                                                                    <>
                                                                        <div className={styles.innerItemContainer}>


                                                                            <div className={styles.itemInfoContainer}>


                                                                                <div className={styles.itemNameTypeEditContainer}>
                                                                                    <input
                                                                                        type="text"
                                                                                        placeholder="Name"
                                                                                        value={item.name}
                                                                                        onChange={(e) => handleItemChange(sectionIndex, itemIndex, 'name', e.target.value)}
                                                                                        className={styles.editItemName}
                                                                                    />
                                                                                    <CustomDropdownSecondary
                                                                                        options={itemTypes.map(type => getReadableItemType(type))} // Display readable labels
                                                                                        value={getReadableItemType(item.type)} // Show readable label
                                                                                        onChange={(selected) => {
                                                                                            const backendValue = Object.keys(itemTypeMapping).find(key => itemTypeMapping[key] === selected);
                                                                                            handleItemChange(sectionIndex, itemIndex, 'type', backendValue);
                                                                                        }}
                                                                                        className={styles.editItemType}
                                                                                    />


                                                                                </div>
                                                                                <input
                                                                                    type="text"
                                                                                    placeholder="Description"
                                                                                    value={item.description}
                                                                                    onChange={(e) => handleItemChange(sectionIndex, itemIndex, 'description', e.target.value)}
                                                                                    className={styles.editItemDescription}
                                                                                />
                                                                                {(item.type === 'text' || item.type === 'multiple_text') && (
                                                                                    <div className={styles.textTypeInputContainer}>
                                                                                        <CustomDropdownSecondary
                                                                                            options={textInputTypes.map(type => getReadableTextInputType(type))} // Show readable labels
                                                                                            value={getReadableTextInputType(item.text_input_type || 'plain')} // Display readable label
                                                                                            onChange={(selected) => {
                                                                                                const backendValue = Object.keys(textInputTypeMapping).find(key => textInputTypeMapping[key] === selected);
                                                                                                handleItemChange(sectionIndex, itemIndex, 'text_input_type', backendValue);
                                                                                            }}
                                                                                        />

                                                                                    </div>
                                                                                )}
                                                                                {item.type === 'file' && (
                                                                                    <CustomDropdownSecondary
                                                                                        options={[
                                                                                            'Account Details/Documents (AM Related)',
                                                                                            'Client Assets',
                                                                                            'Design Team',
                                                                                            'Listings/Review',
                                                                                            'SEM',
                                                                                            'SEO',
                                                                                            'Social and Content Work',
                                                                                            'Verification Documentation',
                                                                                            'Website Work/Client Images'
                                                                                        ]}
                                                                                        value={item.subfolder || ''}
                                                                                        onChange={(value) => handleItemChange(sectionIndex, itemIndex, 'subfolder', value)}
                                                                                        placeholder="Select a subfolder"
                                                                                    />
                                                                                )}
                                                                                {(item.type === 'multiple_choice' || item.type === 'checkboxes') && (
                                                                                    <div className={styles.optionInputsContainer}>
                                                                                        {item.options.map((option, optionIndex) => (
                                                                                            <div key={optionIndex} className={styles.optionInputWrapper}>
                                                                                                {(item.type === 'checkboxes') && (
                                                                                                    <div className={styles.checkbox}>

                                                                                                    </div>
                                                                                                )}
                                                                                                {(item.type === 'multiple_choice') && (
                                                                                                    <div className={styles.circle}>

                                                                                                    </div>
                                                                                                )}

                                                                                                <input
                                                                                                    type="text"
                                                                                                    placeholder={`Option ${optionIndex + 1}`}
                                                                                                    value={option}
                                                                                                    onChange={(e) => {
                                                                                                        const newOptions = [...item.options];
                                                                                                        newOptions[optionIndex] = e.target.value;
                                                                                                        handleItemChange(sectionIndex, itemIndex, 'options', newOptions);
                                                                                                    }}
                                                                                                    className={styles.multipleChoiceOption}
                                                                                                />
                                                                                                <button
                                                                                                    type="button"
                                                                                                    className={styles.deleteOptionButton}
                                                                                                    onClick={() => {
                                                                                                        const newOptions = item.options.filter((_, i) => i !== optionIndex);
                                                                                                        handleItemChange(sectionIndex, itemIndex, 'options', newOptions);
                                                                                                    }}
                                                                                                >
                                                                                                    x
                                                                                                </button>
                                                                                            </div>
                                                                                        ))}
                                                                                        <button
                                                                                            type="button"
                                                                                            className={styles.addOptionButton}
                                                                                            onClick={() => {
                                                                                                const newOptions = [...item.options, ''];
                                                                                                handleItemChange(sectionIndex, itemIndex, 'options', newOptions);
                                                                                            }}
                                                                                        >
                                                                                            + Add Option
                                                                                        </button>
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                            <div className={styles.itemActionButtons}>

                                                                                <button onClick={() => handleDeleteItem(sectionIndex, itemIndex)} className={styles.deleteButton}
                                                                                    title='Delete Section'
                                                                                >
                                                                                    <img src={deleteIcon} alt="Delete" className={styles.icon} />
                                                                                </button>

                                                                                <button
                                                                                    onClick={() => handleToggleLock(sectionIndex, itemIndex)}
                                                                                    className={`${styles.itemLockButton} ${item.locked ? styles.locked : styles.unlocked}`}
                                                                                    title='Require Ferocious Approval For Changes'
                                                                                >
                                                                                    <span className="material-icons">{item.locked ? 'lock' : 'lock_open'}</span>
                                                                                </button>
                                                                                <button
                                                                                    onClick={() => handleToggleOptional(sectionIndex, itemIndex)}
                                                                                    className={`${styles.itemOptionalButton} ${item.optional ? styles.optional : styles.notOptional}`}
                                                                                    title='Make Item Optional'
                                                                                >
                                                                                    <span className="material-symbols-outlined">asterisk</span>
                                                                                </button>
                                                                                <button
                                                                                    onClick={() => moveItemUp(sectionIndex, itemIndex)}
                                                                                    className={styles.moveButton}
                                                                                    disabled={itemIndex === 0}
                                                                                >
                                                                                    <span className="material-icons">keyboard_arrow_up</span>
                                                                                </button>
                                                                                <button
                                                                                    onClick={() => moveItemDown(sectionIndex, itemIndex)}
                                                                                    className={styles.moveButton}
                                                                                    disabled={itemIndex === section.items.length - 1}
                                                                                >
                                                                                    <span className="material-icons">keyboard_arrow_down</span>
                                                                                </button>
                                                                                {(item.type === 'multiple_choice' || item.type === 'checkboxes') && (
                                                                                    <button onClick={() => handleAddConditionalItem(sectionIndex, itemIndex)} className={styles.addConditionalButton}
                                                                                        title='Add Conditional Item'>
                                                                                        <ConditionalIcon className={styles.icon} />
                                                                                    </button>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <div>{item.name}{!item.optional ? '*' : ''}</div>
                                                                        <div>{item.type}</div>
                                                                        <div>{item.description}</div>

                                                                        <div>{item.type === 'text' || item.type === 'multiple_text' ? `Text Type: ${item.text_input_type}` : renderOptions(item)}</div>
                                                                        {item.type === 'file' && item.subfolder && <div>Subfolder: {item.subfolder}</div>}

                                                                    </>
                                                                )}


                                                            </div>
                                                            {
                                                                item.conditionalItems && item.conditionalItems.map((conditionalItem, conditionalIndex) => (
                                                                    <div key={conditionalIndex} className={styles.conditionalItem}>

                                                                        {isEditMode ? (
                                                                            <div className={styles.conditionalItemContainer}>
                                                                                <div className={styles.itemNameTypeEditContainer}>
                                                                                    <input
                                                                                        type="text"
                                                                                        placeholder="Name"
                                                                                        value={conditionalItem.name}
                                                                                        onChange={(e) => handleConditionalItemChange(sectionIndex, itemIndex, conditionalIndex, 'name', e.target.value)}
                                                                                        className={styles.editItemName}
                                                                                    />
                                                                                    <CustomDropdownSecondary
                                                                                        options={itemTypes.map(type => getReadableItemType(type))} // Display readable labels
                                                                                        value={getReadableItemType(conditionalItem.type)} // Show readable label
                                                                                        onChange={(selected) => {
                                                                                            const backendValue = Object.keys(itemTypeMapping).find(key => itemTypeMapping[key] === selected);
                                                                                            handleConditionalItemChange(sectionIndex, itemIndex, conditionalIndex, 'type', backendValue);
                                                                                        }}
                                                                                    />

                                                                                </div>
                                                                                <input
                                                                                    type="text"
                                                                                    placeholder="Description"
                                                                                    value={conditionalItem.description}
                                                                                    onChange={(e) => handleConditionalItemChange(sectionIndex, itemIndex, conditionalIndex, 'description', e.target.value)}
                                                                                    className={styles.editItemDescription}
                                                                                />

                                                                                {(conditionalItem.type === 'multiple_choice' || conditionalItem.type === 'checkboxes') && (
                                                                                    <div className={styles.optionInputsContainer}>
                                                                                        {conditionalItem.options && Array.isArray(conditionalItem.options) && conditionalItem.options.map((option, optionIndex) => (
                                                                                            <div key={optionIndex} className={styles.optionInputWrapper}>
                                                                                                {(conditionalItem.type === 'checkboxes') && <div className={styles.checkbox}></div>}
                                                                                                {(conditionalItem.type === 'multiple_choice') && <div className={styles.circle}></div>}

                                                                                                <input
                                                                                                    type="text"
                                                                                                    placeholder={`Option ${optionIndex + 1}`}
                                                                                                    value={option}
                                                                                                    onChange={(e) => {
                                                                                                        const newOptions = [...conditionalItem.options];
                                                                                                        newOptions[optionIndex] = e.target.value;
                                                                                                        handleConditionalItemChange(sectionIndex, itemIndex, conditionalIndex, 'options', newOptions);
                                                                                                    }}
                                                                                                    className={styles.multipleChoiceOption}
                                                                                                />
                                                                                                <button
                                                                                                    type="button"
                                                                                                    className={styles.deleteOptionButton}
                                                                                                    onClick={() => {
                                                                                                        const newOptions = conditionalItem.options.filter((_, i) => i !== optionIndex);
                                                                                                        handleConditionalItemChange(sectionIndex, itemIndex, conditionalIndex, 'options', newOptions);
                                                                                                    }}
                                                                                                >
                                                                                                    x
                                                                                                </button>
                                                                                            </div>
                                                                                        ))}

                                                                                        {/* Only show "Add Option" button for multiple_choice or checkboxes */}
                                                                                        {(conditionalItem.type === 'multiple_choice' || conditionalItem.type === 'checkboxes') && (
                                                                                            <button
                                                                                                type="button"
                                                                                                className={styles.addOptionButton}
                                                                                                onClick={() => {
                                                                                                    const newOptions = [...(conditionalItem.options || []), ''];
                                                                                                    handleConditionalItemChange(sectionIndex, itemIndex, conditionalIndex, 'options', newOptions);
                                                                                                }}
                                                                                            >
                                                                                                + Add Option
                                                                                            </button>
                                                                                        )}
                                                                                    </div>
                                                                                )}





                                                                                {(conditionalItem.type === 'text' || conditionalItem.type === 'multiple_text') && (
                                                                                    <div className={styles.textTypeInputContainer}>
                                                                                        <CustomDropdownSecondary
                                                                                            options={textInputTypes.map(type => getReadableTextInputType(type))} // Show readable labels
                                                                                            value={getReadableTextInputType(item.text_input_type || 'plain')}
                                                                                            //onChange={(value) => handleConditionalItemChange(sectionIndex, itemIndex, conditionalIndex, 'text_input_type', value)}
                                                                                            onChange={(selected) => {
                                                                                                const backendValue = Object.keys(textInputTypeMapping).find(key => textInputTypeMapping[key] === selected);
                                                                                                handleConditionalItemChange(sectionIndex, itemIndex, conditionalIndex, 'text_input_type', backendValue);
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                )}
                                                                                {conditionalItem.type === 'file' && (
                                                                                    <CustomDropdownSecondary
                                                                                        options={[
                                                                                            'Account Details/Documents (AM Related)',
                                                                                            'Client Assets',
                                                                                            'Design Team',
                                                                                            'Listings/Review',
                                                                                            'SEM',
                                                                                            'SEO',
                                                                                            'Social and Content Work',
                                                                                            'Verification Documentation',
                                                                                            'Website Work/Client Images'
                                                                                        ]}
                                                                                        value={conditionalItem.subfolder || ''}
                                                                                        onChange={(value) => handleConditionalItemChange(sectionIndex, itemIndex, conditionalIndex, 'subfolder', value)}
                                                                                        placeholder="Select a subfolder"
                                                                                    />
                                                                                )}
                                                                                <div className={styles.showOnContainer}>
                                                                                    <p className={styles.showOnText}>Show on:</p>
                                                                                    <CustomDropdownSecondary
                                                                                        options={conditionalItem.showOnOptions || []}
                                                                                        value={conditionalItem.showOn}
                                                                                        onChange={(value) => handleConditionalItemChange(sectionIndex, itemIndex, conditionalIndex, 'showOn', value)}
                                                                                    />
                                                                                </div>

                                                                            </div>
                                                                        ) : (
                                                                            <div className={styles.conditionalItemDisplay}>
                                                                                <div><strong>Conditional Item:</strong> {conditionalItem.name}</div>
                                                                                <div><strong>Item type:</strong> {conditionalItem.type}</div>
                                                                                <div><strong>Show on:</strong> {conditionalItem.showOn}</div>
                                                                                {(conditionalItem.type === 'text' || conditionalItem.type === 'multiple_text') && (
                                                                                    <div><strong>Text Type:</strong> {conditionalItem.text_input_type}</div>
                                                                                )}
                                                                                {conditionalItem.type === 'file' && (
                                                                                    <div><strong>Subfolder:</strong> {conditionalItem.subfolder || 'Not specified'}</div>
                                                                                )}
                                                                            </div>
                                                                        )}
                                                                        <div className={styles.itemActionButtons}>
                                                                            <button onClick={() => handleDeleteConditionalItem(sectionIndex, itemIndex, conditionalIndex)}
                                                                                className={styles.deleteButton}
                                                                                title='Delete Conditional Item'
                                                                            >

                                                                                <img src={deleteIcon} alt="Delete" className={styles.icon} />
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                ))
                                                            }
                                                        </div>
                                                    ))}
                                                    {isEditMode && (
                                                        <div className={styles.addNew} onClick={() => handleAddItem(sectionIndex)}>
                                                            + Add New Checklist Item
                                                        </div>
                                                    )}
                                                </div>
                                            </>
                                        )}
                                    </div>
                                ))}
                                {isEditMode && (
                                    <div className={styles.addSectionContainer}>
                                        <button onClick={handleAddSection} className={styles.addSectionButton}>
                                            Add Section
                                        </button>
                                    </div>

                                )}

                            </div>
                        </div >
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <>
            <NavBar />
            <TabBar tabs={['Default Checklist Items', 'Team Members']} currentTab={currentTab} setCurrentTab={setCurrentTab} />
            <div className={styles.superAdminDashboard}>
                <div className={styles.dashboard}>
                    <div className={styles.header}>
                    </div>
                    {renderContent()}
                </div>
            </div>
            <ToastContainer className="custom-toast-container" />
        </>
    );
};

export default SuperAdminDashboard;
