import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getAllClients, deleteClient, updateClient, getOptions, createActivityLog } from '../api'; // Import the logging function
import NavBar from './NavBar';
import styles from './ExistingClients.module.css';
import CustomDropdown from './CustomDropdown'; // Assuming you have a CustomDropdown component
import { FaChevronUp, FaChevronDown } from 'react-icons/fa';
import SearchBar from './SearchBar';

const ExistingClients = () => {
    const [clients, setClients] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [clientToDelete, setClientToDelete] = useState(null);
    const [editMode, setEditMode] = useState(false);
    const [editedClient, setEditedClient] = useState({});
    const [strategistOptions, setStrategistOptions] = useState([]);
    const [managerOptions, setManagerOptions] = useState([]);
    const [userType, setUserType] = useState('');
    const [sortConfig, setSortConfig] = useState(() => {
        const savedSortConfig = JSON.parse(localStorage.getItem('sortConfig'));
        return savedSortConfig || { key: 'dateCreated', direction: 'descending' };
    });
    const [originalValues, setOriginalValues] = useState({});
    const [deleteInput, setDeleteInput] = useState('');

    useEffect(() => {
        const getUserType = () => {
            const storedUser = JSON.parse(localStorage.getItem('user'));
            if (storedUser && storedUser.userType) {
                console.log('Retrieved userType:', storedUser.userType);
                setUserType(storedUser.userType);
            }
        };

        const fetchClients = async () => {
            try {
                const response = await getAllClients();
                setClients(response.data);
                setOriginalValues(response.data.reduce((acc, client) => {
                    acc[client._id] = {
                        ferociousEmailDistro: client.ferociousEmailDistro,
                        ferociousAccountStrategist: client.ferociousAccountStrategist,
                        ferociousProjectManager: client.ferociousProjectManager
                    };
                    return acc;
                }, {}));
            } catch (error) {
                console.error('Error fetching clients:', error);
            }
        };

        const fetchOptions = async () => {
            try {
                const response = await getOptions();
                console.log(response.data);

                // Extract only the names for the dropdown options
                setStrategistOptions(response.strategists.map(strategist => strategist.name));
                setManagerOptions(response.managers.map(manager => manager.name));
            } catch (error) {
                console.error('Error fetching options:', error);
            }
        };

        getUserType();
        fetchClients();
        fetchOptions();
    }, []);

    const handleDelete = async (clientId) => {
        try {
            const storedUser = JSON.parse(localStorage.getItem('user'));
            const clientName = clients.find(client => client._id === clientId)?.name;

            await deleteClient(clientId);
            setClients(clients.filter(client => client._id !== clientId));
            setShowModal(false);
            setClientToDelete(null);
            setDeleteInput(''); // Reset the delete input

            // Log the activity
            const logMessage = `Deleted ${clientName} portal`;
            await createActivityLog({ user: storedUser.email, action: logMessage });

        } catch (error) {
            console.error('Error deleting client:', error);
        }
    };



    const openDeleteModal = (client) => {
        setClientToDelete(client);
        setShowModal(true);
    };

    const handleEditChange = (clientId, field, value) => {
        setClients(clients.map(client =>
            client._id === clientId ? { ...client, [field]: value } : client
        ));
    };

    const logClientUpdate = async (clientId, clientName, field, oldValue, newValue) => {
        const storedUser = JSON.parse(localStorage.getItem('user'));
        let logMessage = '';
        switch (field) {
            case 'ferociousEmailDistro':
                logMessage = `Changed ${clientName} email distro from ${oldValue} to ${newValue}`;
                break;
            case 'ferociousAccountStrategist':
                logMessage = `Changed ${clientName} Account Manager from ${oldValue} to ${newValue}`;
                break;
            case 'ferociousProjectManager':
                logMessage = `Changed ${clientName} Project Manager from ${oldValue} to ${newValue}`;
                break;
            default:
                return;
        }
        await createActivityLog({ user: storedUser.email, action: logMessage, date: new Date().toISOString() });
    };

    const saveClientEdits = async () => {
        try {
            for (const client of clients) {
                await updateClient(client._id, client);

                // Log changes for relevant fields
                const original = originalValues[client._id];
                if (original.ferociousEmailDistro !== client.ferociousEmailDistro) {
                    await logClientUpdate(client._id, client.name, 'ferociousEmailDistro', original.ferociousEmailDistro, client.ferociousEmailDistro);
                }
                if (original.ferociousAccountStrategist !== client.ferociousAccountStrategist) {
                    await logClientUpdate(client._id, client.name, 'ferociousAccountStrategist', original.ferociousAccountStrategist, client.ferociousAccountStrategist);
                }
                if (original.ferociousProjectManager !== client.ferociousProjectManager) {
                    await logClientUpdate(client._id, client.name, 'ferociousProjectManager', original.ferociousProjectManager, client.ferociousProjectManager);
                }
            }
            setEditMode(false);
        } catch (error) {
            console.error('Error updating client:', error);
        }
    };

    const filteredClients = clients.filter(client =>
        client.name && client.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const formatDateEST = (dateString) => {
    const options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        timeZone: 'America/New_York'
    };
    return new Date(dateString).toLocaleDateString('en-US', options);
};


    const handleSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
        localStorage.setItem('sortConfig', JSON.stringify({ key, direction }));
    };

    const sortedClients = React.useMemo(() => {
        let sortableClients = [...filteredClients];
        if (sortConfig.key !== '') {
            sortableClients.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableClients;
    }, [filteredClients, sortConfig]);

    const getSortIcon = (key) => {
        return <FaChevronUp className={`${styles.sortIcon} ${sortConfig.key === key ? styles[sortConfig.direction] : ''}`} />;
    };


    return (
        <>
            <NavBar />
            <div className={styles.container}>
                <div className={styles.headerContainer}>
                    <h2>Active <span className={styles.secondaryTitle}>Client Portals</span></h2>
                    {userType === 'Admin' || userType === 'Super Admin' ? (
                        <div className={styles.editButtonContainer}>
                            <button
                                className={`${styles.editButton} ${editMode ? styles.editMode : ''}`}
                                onClick={() => setEditMode(!editMode)}
                            >
                                <span className="material-symbols-outlined">{editMode ? 'close' : 'edit'}</span>
                            </button>
                            {editMode && (
                                <button
                                    className={styles.saveButton}
                                    onClick={saveClientEdits}
                                >
                                    <span className="material-symbols-outlined saveIcon">check</span>
                                </button>
                            )}
                        </div>
                    ) : null}
                </div>
                <div className={styles.bigTable}>
                <div className={styles.searchExtra}>
                    <SearchBar 
                        searchTerm={searchTerm} 
                        setSearchTerm={setSearchTerm} 
                        hintText={'Search by client name'}
                    />
                </div>
                    <table className={styles.table}>
                        <thead>
                            <tr>
                                <th onClick={() => handleSort('name')}>
                                    <div className={styles.columnHeader}>
                                        <span>Client Name</span>
                                        {getSortIcon('name')}
                                    </div>
                                </th>
                                <th>Link to Portal</th>
                                <th onClick={() => handleSort('dateCreated')}>
                                    <div className={styles.columnHeader}>
                                        <span>Date Created</span>
                                        {getSortIcon('dateCreated')}
                                    </div>
                                </th>
                                <th onClick={() => handleSort('ferociousEmailDistro')}>
                                    <div className={styles.columnHeader}>
                                        <span>Email Distro</span>
                                        {getSortIcon('ferociousEmailDistro')}
                                    </div>
                                </th>
                                <th onClick={() => handleSort('ferociousAccountStrategist')}>
                                    <div className={styles.columnHeader}>
                                        <span>Account Manager</span>
                                        {getSortIcon('ferociousAccountStrategist')}
                                    </div>
                                </th>
                                <th onClick={() => handleSort('ferociousProjectManager')}>
                                    <div className={styles.columnHeader}>
                                        <span>Project Manager</span>
                                        {getSortIcon('ferociousProjectManager')}
                                    </div>
                                </th>
                                {userType === 'Super Admin' && <th>Actions</th>}
                                <th>Users</th>
                                <th>Logs</th>
                            </tr>
                        </thead>

                        <tbody>
                            {sortedClients.map(client => (
                                <tr key={client._id}>
                                    {editMode ? (
                                        <>
                                            <td>
                                                <input
                                                    type="text"
                                                    value={client.name}
                                                    onChange={e => handleEditChange(client._id, 'name', e.target.value)}
                                                />
                                            </td>
                                            <td><Link className={styles.viewPortalBtn} to={`admin/client-portal/${client.url}`}>View Portal</Link></td>
                                            <td>{formatDateEST(client.dateCreated)}</td>
                                            <td>
                                                <input
                                                    type="text"
                                                    value={client.ferociousEmailDistro}
                                                    onChange={e => handleEditChange(client._id, 'ferociousEmailDistro', e.target.value)}
                                                />
                                            </td>
                                            <td>
                                                <CustomDropdown
                                                    options={strategistOptions}
                                                    value={client.ferociousAccountStrategist}
                                                    onChange={value => handleEditChange(client._id, 'ferociousAccountStrategist', value)}
                                                />
                                            </td>
                                            <td>
                                                <CustomDropdown
                                                    options={managerOptions}
                                                    value={client.ferociousProjectManager}
                                                    onChange={value => handleEditChange(client._id, 'ferociousProjectManager', value)}
                                                />
                                            </td>
                                            <td>
                                                <button className={styles.deleteUser} onClick={() => openDeleteModal(client)}>Delete</button>
                                            </td>
                                        </>
                                    ) : (
                                        <>
                                            <td>{client.name}</td>
                                            <td><Link className={styles.viewPortalBtn} to={`/admin/client-portal/${client.url}`}>View Portal</Link></td>
                                            <td>{formatDateEST(client.dateCreated)}</td>
                                            <td>{client.ferociousEmailDistro || ''}</td>
                                            <td>{client.ferociousAccountStrategist || ''}</td>
                                            <td>{client.ferociousProjectManager || ''}</td>
                                            <td>
                                                <button className={styles.deleteUser} onClick={() => openDeleteModal(client)}>Delete</button>
                                            </td>
                                        </>
                                    )}
                                    <td><Link className={styles.viewUsersBtn} to={`/admin/client/${client._id}/users`}>View Users</Link></td>
                                    <td><Link className={styles.viewLogsBtn} to={`/admin/client/${client._id}/logs`}>View Logs</Link></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                {showModal && (
                    <div className={styles.modal}>
                        <div className={styles.modalContent}>
                            <h3>Are you sure you want to permanently delete {clientToDelete?.name}'s portal?</h3>
                            <p>This action cannot be undone and will remove access immediately.</p>
                            <input
                                type="text"
                                value={deleteInput}
                                onChange={(e) => setDeleteInput(e.target.value)}
                                placeholder="Type 'delete' to confirm"
                                className={styles.deleteInput}
                            />
                            <div className={styles.modalActions}>
                                <button
                                    onClick={() => handleDelete(clientToDelete._id)}
                                    className={`${styles.confirmButton} ${deleteInput !== 'delete' ? styles.disabledButton : ''}`}
                                    disabled={deleteInput !== 'delete'}
                                >
                                    Confirm
                                </button>
                                <button onClick={() => setShowModal(false)} className={styles.cancelButton}>
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                )}

            </div>
        </>
    );
};

export default ExistingClients;
