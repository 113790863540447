import React, { useState, useEffect } from "react";
import styles from "./EditTeamMember.module.css"; // Scoped styles
import Modal from "./Modal";
import ImageUpload from "./ImageUpload";
import { updateTeamMember, uploadProfilePic, deleteTeamMember, addTeamMember, updateManagersAndStrategists, removeManagerOrStrategist } from "../../api";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../ToastifyCustom.css';
import blankProfilePic from "../../assets/blank_profile_pic.png"; // 🔹 Import default image


const EditTeamMember = ({ member, onSave, onClose, managersAndStrategists }) => {
    const [editedMember, setEditedMember] = useState({ firstName: "", lastName: "", title: "", email: "", profilePicURL: "" });
    const [isProjectManager, setIsProjectManager] = useState(false);
    const [isAccountManager, setIsAccountManager] = useState(false);

    useEffect(() => {
        if (member) {
            setEditedMember({ ...member });

            // Check if the member's email exists in managers or strategists
            setIsProjectManager(
                managersAndStrategists?.managers?.some(m => m.email === member.email) || false
            );
            setIsAccountManager(
                managersAndStrategists?.strategists?.some(s => s.email === member.email) || false
            );
        }
    }, [member, managersAndStrategists]);  // 🔹 Also re-run when managersAndStrategists change

    const handleRoleChange = (e) => {
        const { name, checked } = e.target;
        if (name === "isProjectManager") setIsProjectManager(checked);
        if (name === "isAccountManager") setIsAccountManager(checked);
    };



    const handleChange = (e) => {
        const { name, value } = e.target;
        setEditedMember((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const isSaveDisabled = !editedMember.firstName.trim() ||
        !editedMember.lastName.trim() ||
        !editedMember.email.trim();


    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const updatedMember = {
                ...editedMember,
                // TEMP: don't use fallback at all
                // profilePicURL: editedMember.profilePicURL?.trim() || blankProfilePic,
                profilePicURL: editedMember.profilePicURL, 
                isProjectManager,
                isAccountManager
            };
            

            if (!member._id) {
                // ✅ Create a new team member
                await addTeamMember(updatedMember);
            } else {
                // ✅ Update existing team member
                await updateTeamMember(member._id, updatedMember);
            }

            if (isAccountManager) {
                await updateManagersAndStrategists({
                    strategists: [{ name: editedMember.firstName, email: editedMember.email }],
                    managers: []
                });
            } else {
                await removeManagerOrStrategist(editedMember.email, "strategist");
            }
            
            if (isProjectManager) {
                await updateManagersAndStrategists({
                    strategists: [],
                    managers: [{ name: editedMember.firstName, email: editedMember.email }]
                });
            } else {
                await removeManagerOrStrategist(editedMember.email, "manager");
            }
            

            // ✅ Refresh all team members and managers/strategists in the parent component
            onSave();
            onClose();

        } catch (error) {
            console.error("❌ Error saving team member:", error);
            toast.error("Failed to save team member.");
        }
    };










    const handleImageUpload = async (file) => {
        const formData = new FormData();
        formData.append("file", file);  // 🔹 Must match backend's `upload.single('file')`
        formData.append("team_member_id", member._id);  // 🔹 Send the team member ID

        try {
            const response = await uploadProfilePic(formData);
            setEditedMember((prev) => ({
                ...prev,
                profilePicURL: response.profilePicURL, // 🔹 Update with the Cloudinary URL
            }));
        } catch (error) {
            console.error("Error uploading profile picture:", error);
        }
    };

    const handleDelete = async () => {
        const confirmDelete = window.confirm("Are you sure you want to delete this team member?");
        if (!confirmDelete) return;

        try {
            var teamMember = member.firstName + " " + member.lastName;
            await deleteTeamMember(member._id);
            onSave(null);  // 🔹 Remove member from frontend state
            onClose();  // 🔹 Close the modal
            toast.success(`${teamMember} deleted successfully!`);  // 🔹 Show toast notification
        } catch (error) {
            console.error("Error deleting team member:", error);
            toast.error("Failed to delete team member.");
        }
    };



    return (
        <Modal isOpen={!!member} onClose={onClose}>
            <div className={styles.modalContent}>
                <h2 className={styles.title}>Edit Team Member</h2>
                <form onSubmit={handleSubmit} className={styles.form}>
                    <div className={styles.imageContainer}>
                        <ImageUpload
                            currentImage={editedMember.profilePicURL}
                            onImageChange={handleImageUpload}
                        />
                        <label className={styles.label}>Upload Profile Picture</label>

                    </div>
                    <div className={styles.formGroup}>
                        <label className={styles.label}>First Name:</label>
                        <input
                            type="text"
                            name="firstName"
                            value={editedMember.firstName}
                            onChange={handleChange}
                            spellCheck={false}
                            className={styles.input}
                        />
                    </div>
                    <div className={styles.formGroup}>
                        <label className={styles.label}>Last Name:</label>
                        <input
                            type="text"
                            name="lastName"
                            value={editedMember.lastName}
                            onChange={handleChange}
                            spellCheck={false}
                            className={styles.input}
                        />
                    </div>

                    <div className={styles.formGroup}>
                        <label className={styles.label}>Role:</label>
                        <input
                            type="text"
                            name="title"
                            value={editedMember.title}
                            onChange={handleChange}
                            className={styles.input}
                        />
                    </div>

                    <div className={styles.formGroup}>
                        <label className={styles.label}>Email:</label>
                        <input
                            type="email"
                            name="email"
                            value={editedMember.email}
                            onChange={handleChange}
                            className={styles.input}
                        />
                    </div>

                    <div className={styles.formGroup}>
                        <label className={styles.label}>
                            <input
                                type="checkbox"
                                name="isProjectManager"
                                checked={isProjectManager}
                                onChange={handleRoleChange}
                            />
                            Project Manager
                        </label>
                    </div>

                    <div className={styles.formGroup}>
                        <label className={styles.label}>
                            <input
                                type="checkbox"
                                name="isAccountManager"
                                checked={isAccountManager}
                                onChange={handleRoleChange}
                            />
                            Account Manager
                        </label>
                    </div>


                    <div className={styles.buttonContainer}>
                        <button
                            type="submit"
                            className={`${styles.saveButton} ${isSaveDisabled ? styles.disabledButton : ""}`}
                            disabled={isSaveDisabled}
                        >
                            Save
                        </button>

                        <button type="button" onClick={handleDelete} className={styles.deleteBtn}>Delete</button>
                        <button type="button" onClick={onClose} className={styles.cancelButton}>Cancel</button>
                    </div>
                </form>

            </div>
        </Modal>
    );
};

export default EditTeamMember;
