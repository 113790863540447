import React, { useState, useEffect, useRef } from 'react';
import { FaEllipsisV, FaEdit, FaTrash, FaChevronUp } from 'react-icons/fa'; // Import arrow icon
import styles from './AddUsers.module.css'; // Import the CSS module for AddUsers
import NavBar from './NavBar'; // Import NavBar component
import CustomDropdown from './CustomDropdown'; // Import CustomDropdown component
import { ToastContainer, toast } from 'react-toastify'; // Import toast
import 'react-toastify/dist/ReactToastify.css'; // Import toast styles
import { getUsers, addUser, deleteUser, updateUserType, createActivityLog } from '../api'; // Import the new API functions
import SearchBar from './SearchBar';

const AddUsers = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [userType, setUserType] = useState('Account Manager'); // Default user type
    const [users, setUsers] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [userToDelete, setUserToDelete] = useState(null);
    const [userEmail, setUserEmail] = useState('');
    const [currentUserType, setCurrentUserType] = useState('');
    const [openMenuIndex, setOpenMenuIndex] = useState(null);
    const [sortConfig, setSortConfig] = useState(() => {
        const savedSortConfig = JSON.parse(localStorage.getItem('sortConfig'));
        return savedSortConfig || { key: '', direction: '' };
    });

    const [isEditing, setIsEditing] = useState(false);
    const [editUserType, setEditUserType] = useState('');
    const [editUserId, setEditUserId] = useState(null);

    const menuRef = useRef(null);

    useEffect(() => {
        // Fetch users from the backend
        const fetchUsers = async () => {
            try {
                const response = await getUsers();
                setUsers(response.data);
            } catch (error) {
                console.error('Error fetching users:', error);
            }
        };

        fetchUsers();

        // Get user email from local storage
        const storedUser = JSON.parse(localStorage.getItem('user'));
        if (storedUser && storedUser.email) {
            setUserEmail(storedUser.email);
            setCurrentUserType(storedUser.userType);
        }
    }, []);




    const toggleMenu = (userId) => {
        setOpenMenuIndex(openMenuIndex === userId ? null : userId);
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        const newUser = { name, email, userType };

        try {
            const response = await addUser(newUser);
            setUsers([...users, response.data]);
            setName('');
            setEmail('');
            setUserType('Account Manager');

            // Show a success toast
            toast.success('User added successfully!');

            // Log the addition of a new user
            await createActivityLog({
                user: userEmail,
                action: `Added ${name} (${email}) as ${userType}`,
                date: new Date().toISOString()
            });
        } catch (error) {
            console.error('Error adding user:', error);

            // Show an error toast
            toast.error('Failed to add user.');
        }
    };


    const handleSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
        localStorage.setItem('sortConfig', JSON.stringify({ key, direction }));
    };

    const handleUserTypeChange = async (userId, newUserType) => {
        // Find the user by userId to get the user's name
        const userToUpdate = users.find(user => user._id === userId);

        const updatedUsers = users.map(user =>
            user._id === userId ? { ...user, userType: newUserType } : user
        );
        setUsers(updatedUsers);

        // Update the user type in the backend
        try {
            await updateUserType(userId, { userType: newUserType });

            // Log the change of user type
            await createActivityLog({
                user: userEmail,
                action: `Changed user type for ${userToUpdate.name} to ${newUserType}`, // Use user's name
                date: new Date().toISOString()
            });

            // Show a success toast
            toast.success('User type updated successfully!');

            // Close the edit mode and popup
            setIsEditing(false);
            setEditUserId(null);
            setOpenMenuIndex(null);
        } catch (error) {
            console.error('Error updating user type:', error);
            toast.error('Failed to update user type.');
        }
    };




    const handleDeleteUser = (userId) => {
        const selectedUser = users.find(user => user._id === userId);
        // Check if the current user has the necessary permissions
        if (
            (currentUserType === 'Account Manager' && ['Admin', 'Super Admin'].includes(selectedUser.userType)) ||
            (currentUserType === 'Admin' && selectedUser.userType === 'Super Admin')
        ) {
            alert("You don't have permission to delete this user.");
            return;
        }

        setUserToDelete(selectedUser);
        setShowModal(true);
    };


    const confirmDeleteUser = async () => {
        if (userToDelete) {
            try {
                await deleteUser(userToDelete._id);
                const updatedUsers = users.filter(user => user._id !== userToDelete._id);
                setUsers(updatedUsers);

                // Log the deletion of a user
                await createActivityLog({
                    user: userEmail,
                    action: `Deleted ${userToDelete.name} (${userToDelete.email})`,
                    date: new Date().toISOString()
                });
            } catch (error) {
                console.error('Error deleting user:', error);
            }
            setShowModal(false);
            setUserToDelete(null);
        }
    };

    const handleEditUser = (userId) => {
        console.log('handleEditUser called for:', userId); // Log user information
        if (editUserId === userId) {
            console.log('User is already being edited, toggling off edit mode');
            // If the user is already being edited, toggle editing off
            setIsEditing(false);
            setEditUserId(null);
        } else {
            console.log('Starting edit mode for user ID:', userId);
            // Start editing this user
            setIsEditing(true);
            const user = users.find(u => u._id === userId); // Find the user by ID
            setEditUserType(user.userType);
            setEditUserId(userId);
        }
    };




    const saveEditUserType = async () => {
        try {
            await updateUserType(editUserId, { userType: editUserType });

            // Update the local users state
            const updatedUsers = users.map((user) =>
                user._id === editUserId ? { ...user, userType: editUserType } : user
            );
            setUsers(updatedUsers);

            // Show a success toast
            toast.success('User type updated successfully!');

            // Log the change of user type
            await createActivityLog({
                user: userEmail,
                action: `Changed user type for userId: ${editUserId} to ${editUserType}`,
                date: new Date().toISOString(),
            });

            // Reset editing state
            setIsEditing(false);
            setEditUserId(null);
            setOpenMenuIndex(null);
        } catch (error) {
            console.error('Error saving user type:', error);
            toast.error('Failed to update user type.');
        }
    };

    // Filter and sort users based on the search query and sorting configuration
    const sortedFilteredUsers = [...users]
        .filter((user) =>
            user.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            user.email.toLowerCase().includes(searchQuery.toLowerCase())
        )
        .sort((a, b) => {
            if (sortConfig.key) {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
            }
            return 0;
        });

    return (
        <>
            <NavBar />
            <ToastContainer /> {/* Add this line */}
            <div className={styles.addUsersContainer}>
                <h1>Employee <span className={styles.secondaryTitle}>User Management</span></h1>
                <form onSubmit={handleSubmit} className={styles.addUsersForm}>
                    <div className={styles.formGroup}>
                        <label htmlFor="name">Name</label>
                        <input
                            type="text"
                            id="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                        />
                    </div>
                    <div className={styles.formGroup}>
                        <label htmlFor="email">Email</label>
                        <input
                            type="email"
                            id="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <div className={styles.formGroup}>
                        <label htmlFor="userType">User Type</label>
                        <CustomDropdown
                            options={
                                currentUserType === 'Super Admin'
                                    ? ['Account Manager', 'Admin', 'Super Admin']
                                    : currentUserType === 'Admin'
                                        ? ['Account Manager', 'Admin']
                                        : ['Account Manager']
                            }
                            value={userType}
                            onChange={(value) => setUserType(value)}
                        />
                    </div>
                    <button type="submit" className={styles.submitButton}>Add User</button>
                </form>

                <div className={styles.bigTable}>
                    <div className={styles.searchExtra}>
                        <SearchBar 
                            searchTerm={searchQuery}
                            setSearchTerm={setSearchQuery} 
                            hintText={'Search by name or email'}
                            />
                    </div>

                    {sortedFilteredUsers.length > 0 && (
                        <table className={styles.usersTable}>
                            <thead>
                                <tr>
                                    <th onClick={() => handleSort('name')}>
                                        Name
                                        <FaChevronUp
                                            className={`${styles.sortIcon} ${sortConfig.key === 'name' ? styles[sortConfig.direction] : ''}`}
                                        />
                                    </th>
                                    <th onClick={() => handleSort('email')}>
                                        Email
                                        <FaChevronUp
                                            className={`${styles.sortIcon} ${sortConfig.key === 'email' ? styles[sortConfig.direction] : ''}`}
                                        />
                                    </th>
                                    <th onClick={() => handleSort('userType')}>
                                        User Type
                                        <FaChevronUp
                                            className={`${styles.sortIcon} ${sortConfig.key === 'userType' ? styles[sortConfig.direction] : ''}`}
                                        />
                                    </th>
                                    {currentUserType !== 'Account Manager' && <th>Actions</th>}
                                </tr>
                            </thead>

                            <tbody>
                                {sortedFilteredUsers.map((user) => (
                                    <tr key={user._id}>
                                        <td>{user.name}</td>
                                        <td>{user.email}</td>
                                        <td>
                                            {isEditing && editUserId === user._id ? (
                                                <CustomDropdown
                                                    options={['Account Manager', 'Admin', 'Super Admin']}
                                                    value={user.userType}
                                                    onChange={(newUserType) => handleUserTypeChange(user._id, newUserType)}
                                                />
                                            ) : (
                                                <span className={
                                                    user.userType === 'Admin' ? styles.adminText :
                                                        user.userType === 'Account Manager' ? styles.accountMgrText :
                                                            user.userType === 'Super Admin' ? styles.superAdminText : ''
                                                }>
                                                    {user.userType}
                                                </span>
                                            )}
                                        </td>

                                        {currentUserType !== 'Account Manager' && (
                                            <td>
                                                <div className={styles.menuContainer} ref={menuRef}>
                                                    <button
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            toggleMenu(user._id); // Use user._id instead of index
                                                        }}
                                                        className={styles.dotsButton}
                                                    >
                                                        <FaEllipsisV />
                                                    </button>
                                                    {openMenuIndex === user._id && ( // Use user._id instead of index
                                                        <div className={`${styles.popupMenu} ${styles.show}`}>
                                                            <>
                                                                {console.log('Rendering Edit/Delete buttons for user:', user)} {/* Log to check if this section is rendered */}
                                                                <button onClick={() => {
                                                                    console.log('Edit button clicked for user ID:', user._id); // Log when the edit button is clicked
                                                                    handleEditUser(user._id); // Pass user._id instead of index
                                                                }}>
                                                                    <FaEdit /> Edit
                                                                </button>

                                                                <button onClick={() => handleDeleteUser(user._id)}> {/* Change handleDeleteUser to use user._id */}
                                                                    <FaTrash /> Delete
                                                                </button>
                                                            </>
                                                        </div>
                                                    )}

                                                </div>
                                            </td>
                                        )}
                                    </tr>
                                ))}
                            </tbody>

                        </table>
                    )}
                </div>
            </div>
            {showModal && (
                <div className={styles.modal}>
                    <div className={styles.modalContent}>
                        <h3>Are you sure you want to delete {userToDelete?.name}'s access?</h3>
                        <p>This cannot be undone and will remove access immediately.</p>
                        <div className={styles.modalActions}>
                            <button onClick={() => setShowModal(false)} className={styles.cancelButton}>Cancel</button>
                            <button onClick={confirmDeleteUser} className={styles.confirmButton}>Confirm</button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default AddUsers;
