import React, { useState, useEffect } from "react";
import styles from "./EditTeamMember.module.css";
import { uploadProfilePic } from "../../api";


const ImageUpload = ({ currentImage, onImageChange }) => {
    const [preview, setPreview] = useState(currentImage || ""); // Prevent undefined errors

    // Ensure preview updates when `currentImage` changes
    useEffect(() => {
        if (currentImage) {
            setPreview(currentImage);
        }
    }, [currentImage]);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreview(reader.result);
                onImageChange(file); // Send file to parent component
            };
            reader.readAsDataURL(file);
        }
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const handleDrop = (event) => {
        event.preventDefault();
        const file = event.dataTransfer.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreview(reader.result);
                onImageChange(file); // Send file to parent component
            };
            reader.readAsDataURL(file);
        }
    };

    return (
        <div
            className={styles.uploadContainer}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            onClick={() => document.getElementById("fileInput").click()} // Click upload
        >
            <input
                type="file"
                id="fileInput"
                accept="image/*"
                onChange={handleFileChange}
                className={styles.fileInput}
            />
            {preview ? (
                <img src={preview} alt="Profile" className={styles.memberImage} />
            ) : (
                <p className={styles.uploadText}>Drag & Drop or Click to Upload</p>
            )}
        </div>
    );
};

export default ImageUpload;
